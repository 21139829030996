import * as TYPES from '../constants';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

export const router = connectRouter(history);

export function loader(state = false, action: any) {
	if (action.type === TYPES.SET_LOADER) {
		return action.value;
	}

	return state;
}

export function title(state = '', action: any) {
	if (action.type === TYPES.SET_TITLE) {
		return action.value;
	}

	return state;
}

export function user(state = null, action: any) {
	if (action.type === TYPES.SET_USER) {
		return action.value;
	} if (action.type === TYPES.SET_LOGOUT) {
		return null;
	}

	return state;
}

export function token(state = '', action: any) {
	if (action.type === TYPES.SET_TOKEN) {
		return action.value;
	} if (action.type === TYPES.SET_LOGOUT) {
		return '';
	}

	return state;
}

export function language(state = '', action: any) {
	if (action.type === TYPES.SET_LANGUAGE) {
		return action.value;
	}

	return state;
}

export function surveys(state = null, action: any) {
	if (action.type === TYPES.SET_SURVEYS) {
		return action.value;
	}

	return state;
}

export function navigation(state = '', action: any) {
	if (action.type === TYPES.SET_NAVIGATION) {
		return action.value;
	}

	return state;
}