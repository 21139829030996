// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error500Content{display:flex;align-items:center;justify-content:center;width:100%;min-height:calc(100vh - 300px);padding:100px 25px 0px;background-color:#f4f4f4}.Error500Content .Error500ContentWrapper{width:100%}@media screen and (min-width: 1600px){.Error500Content .Error500ContentWrapper{width:1600px}}.Error500_Meanwhile_Container{display:flex;flex-direction:column;align-items:center;margin-top:20px}.Error500_Meanwhile_Container div:last-child{margin-top:10px}.Error500_Meanwhile_Container .Error500_Option{display:flex;align-items:center;cursor:pointer}.Error500_Meanwhile_Container .Error500_Option:hover{color:#eb1e6e}.Error500_Meanwhile_Container .Error500_Option span,.Error500_Meanwhile_Container .Error500_Option em{font-weight:700}@media screen and (max-width: 768px){.ant-result{padding:0 !important}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
