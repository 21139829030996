// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SidebarContainer{position:relative;width:260px;min-width:260px;height:100%;max-height:100%;background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff;transition:.5s;border-radius:0;box-shadow:0 2px 22px 0 rgba(0,0,0,.1),0 4px 20px 0 rgba(0,0,0,.15) !important;padding:0 20px 10px;z-index:15}.SidebarContainer *{font-family:Poppins;font-weight:400;text-transform:uppercase;font-size:12px}.SidebarContainer .SidebarHome{margin-bottom:10px}.SidebarContainer .SidebarHome .SidebarLogoContainer{display:flex;align-items:center;height:60px;padding-left:5px}.SidebarContainer .SidebarHome .SidebarLogoContainer .Logo{height:30px;width:auto;object-fit:contain}.SidebarContainer .SidebarSeparator{width:100%;height:1px;margin:1px 0 10px;background-color:#c3c3c3}.SidebarContainer .SidebarContent{height:calc(100% - 21px - 50px);overflow-y:auto;overflow-x:hidden}.SidebarContainer .SidebarContent .SidebarMenuObject{display:flex;align-items:center;padding:0 10px;width:100%;height:55px;margin-bottom:6px;user-select:none;transition:.5s;cursor:pointer}.SidebarContainer .SidebarContent .SidebarMenuObject:hover{color:#3db2c0}.SidebarContainer.closed{width:90px;min-width:90px;padding:0 20px 10px;margin-left:-90px}.SidebarContainer.closed .SidebarSeparator{width:100%}.SidebarContainer.closed .SidebarMenu .SidebarNavName,.SidebarContainer.closed .SidebarMenu .SidebarSubNavName{opacity:0 !important;transform:translate3d(-25px, 0, 0) !important}.SidebarContainer.closed .SidebarMenu.isSubMenu .SidebarMenuIcon{opacity:1 !important}.SidebarContainer.closed .MenuHasOptions{display:none}@keyframes rotate{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}.caret{display:inline-block;width:0;height:0;margin-left:2px;vertical-align:middle;border-top:4px dashed;border-top:4px solid\\9 ;border-right:4px solid transparent;border-left:4px solid transparent;transition:linear .2s}.caret.__opened{transform:rotate(180deg)}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
