/**
 *
 * Header
 *
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Icon, SVGs } from "components";
import { Drawer } from "antd";
import Strings from "utils/strings";
import logo from "assets/images/logo.png";
import "./styles.scss";
import { setLanguage } from "store/actions";

/* eslint-disable react/prefer-stateless-function */
export class Header extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            mobileMenu: false,
            isMobile: window.innerWidth <= 992,
            scrolled: window.pageYOffset > 50,
            drawerOpen: false,
        };

        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidMount() {
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        // eslint-disable-next-line
        const orientation = (screen.orientation || {}).type;

        this.setState({
            isMobile:
                window.innerWidth <= 992 ||
                (orientation === "landscape-primary" && window.innerHeight <= 480) ||
                (orientation === "landscape-secondary" && window.innerHeight <= 480),
        });
    }

    handleScroll() {
        this.setState({ scrolled: window.pageYOffset > 50 });
    }

    renderDrawer() {
        const { drawerOpen } = this.state;
        const { dispatch } = this.props;

        return (
            <Drawer
                title={null}
                footer={null}
                placement="left"
                onClose={() => this.setState({ drawerOpen: false })}
                width={window.innerWidth < 768 ? "100%" : 400}
                visible={drawerOpen}
                className="mobile-drawer-wrapper"
            >
                <div className="mobile-drawer-background">
                    <SVGs.Waves />
                </div>
                <div className="mobile-drawer-header">
                    <img
                        onClick={() => {
                            this.setState({ drawerOpen: false });
                            dispatch(push("/"));
                        }}
                        src={logo}
                        alt="header logo"
                    />
                </div>
                <div className="mobile-drawer-body">
                    <nav className="navigation-wrapper --nav-mobile">
                        <a
                            href="/"
                            className={
                                window?.location?.pathname === "/" ? "--nav-active" : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/"));
                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.home}
                        </a>
                        <a
                            href="/about-us"
                            className={
                                window?.location?.pathname === "/about-us" ? "--nav-active" : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/about-us"));
                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.aboutUs}
                        </a>
                        <a
                            href="/what-we-do"
                            className={
                                window?.location?.pathname === "/what-we-do"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/what-we-do"));
                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.whatWeDo}
                        </a>
                        <a
                            href="/portfolio"
                            className={
                                window?.location?.pathname === "/portfolio"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/portfolio"));
                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.portfolio}
                        </a>
                        <a
                            href="/work-with-us"
                            className={
                                window?.location?.pathname === "/work-with-us"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/work-with-us"));
                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.workWithUs}
                        </a>
                        <p
                            onClick={() => {
                                const elem = document.querySelector('#contact-us');

                                if (elem) {
                                    window.scrollTo({ top: elem.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' })
                                }

                                this.setState({ drawerOpen: false });
                            }}
                        >
                            {Strings.header.contactUs}
                        </p>
                    </nav>
                </div>
                <div className="mobile-drawer-footer">
                    <div className="language-selector">
                        <button
                            className={Strings.getLanguage() === "pt" ? "--btn-active" : ""}
                            onClick={() => {
                                Strings.setLanguage("pt");
                                dispatch(setLanguage("pt"));
                            }}
                        >
                            PT
                        </button>
                        <span>|</span>
                        <button
                            className={Strings.getLanguage() === "en" ? "--btn-active" : ""}
                            onClick={() => {
                                Strings.setLanguage("en");
                                dispatch(setLanguage("en"));
                            }}
                        >
                            EN
                        </button>
                    </div>
                </div>
            </Drawer>
        );
    }

    render() {
        const { isMobile, scrolled } = this.state;
        const { dispatch } = this.props;

        if (isMobile) {
            return (
                <header
                    className={`header-wrapper${scrolled || window?.location?.pathname === "/500"
                            ? " --header-inverted"
                            : ""
                        }`}
                >
                    <div className="header-inner-container">
                        <div className="logo-wrapper --logo-mobile">
                            <Icon
                                onClick={() => this.setState({ drawerOpen: true })}
                                name="menu"
                            />
                            <img
                                onClick={() => {
                                    dispatch(push("/"));
                                }}
                                src={logo}
                                alt="header logo"
                            />
                        </div>
                    </div>
                    {this.renderDrawer()}
                </header>
            );
        }

        return (
            <header
                className={`header-wrapper${scrolled || window?.location?.pathname === "/500"
                        ? " --header-inverted"
                        : ""
                    }`}
            >
                <div className="header-inner-container">
                    <div className="logo-wrapper">
                        <img
                            onClick={() => {
                                dispatch(push("/"));
                            }}
                            src={logo}
                            alt="header logo"
                        />
                    </div>
                    <nav className="navigation-wrapper">
                        <a
                            href="/about-us"
                            className={
                                window?.location?.pathname === "/about-us" ? "--nav-active" : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/about-us"));
                            }}
                        >
                            {Strings.header.aboutUs}
                        </a>
                        <a
                            href="/what-we-do"
                            className={
                                window?.location?.pathname === "/what-we-do"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/what-we-do"));
                            }}
                        >
                            {Strings.header.whatWeDo}
                        </a>
                        <a
                            href="/portfolio"
                            className={
                                window?.location?.pathname === "/portfolio"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/portfolio"));
                            }}
                        >
                            {Strings.header.portfolio}
                        </a>
                        <a
                            href="/work-with-us"
                            className={
                                window?.location?.pathname === "/work-with-us"
                                    ? "--nav-active"
                                    : ""
                            }
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();

                                dispatch(push("/work-with-us"));
                            }}
                        >
                            {Strings.header.workWithUs}
                        </a>
                    </nav>
                    <div className="contact-wrapper">
                        <button
                            type="button"
                            className="btn-primary"
                            onClick={() => {
                                const elem = document.querySelector("#contact-us");

                                if (elem) {
                                    window.scrollTo({
                                        top: elem.getBoundingClientRect().top + window.scrollY,
                                        behavior: "smooth",
                                    });
                                }
                            }}
                        >
                            {Strings.header.contactUs}
                        </button>
                        <div className="language-selector">
                            <button
                                className={Strings.getLanguage() === "pt" ? "--btn-active" : ""}
                                onClick={() => {
                                    Strings.setLanguage("pt");
                                    dispatch(setLanguage("pt"));
                                }}
                            >
                                PT
                            </button>
                            <span>|</span>
                            <button
                                className={Strings.getLanguage() === "en" ? "--btn-active" : ""}
                                onClick={() => {
                                    Strings.setLanguage("en");
                                    dispatch(setLanguage("en"));
                                }}
                            >
                                EN
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state: any) => ({
    title: state.title,
    user: state.user,
    language: state.language,
    settings: state.settings,
});

export default connect(mapStateToProps)(Header);
