import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Home, Error500, Portfolio, Project, AboutUs, WhatWeDo, WorkWithUs, JobOffer } from 'screens';
import Page from 'screens/Page';

export const offlinePages = ['/login', '/register', '/accept-invite', '/recover-password', '/confirm'];

export class Routes extends React.Component<any, any> {
	shouldComponentUpdate(nextProps: any) {
		const { user, token } = this.props;
		const isLoggedIn = Boolean(user && token);
		const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

		return isLoggedIn !== willBeLoggedIn;
	}

	componentDidUpdate() {
		const elem = document.getElementById('app_content');

		if (elem) {
			elem.scrollTop = 0;
		}
	}

	render() {
		return (
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/portfolio" component={Portfolio} />
				<Route exact path="/portfolio/:id" component={Project} />
				<Route exact path="/about-us" component={AboutUs} />
				<Route exact path="/what-we-do" component={WhatWeDo} />
				<Route exact path="/work-with-us" component={WorkWithUs} />
				<Route exact path="/job-offer/:id" component={JobOffer} />
				<Route exact path="/pages/:id" component={Page} />
				<Route exact path="/500" component={Error500} />
				<Redirect to="/" />
			</Switch>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	user: state.user,
	token: state.token,
});

export default connect(mapStateToProps)(Routes);
