import { Col, notification, Row } from "antd";
import { Device, Icon, SVGs } from "components";
import { push } from "connected-react-router";
import React from "react";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import { setLoader } from "store/actions";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import { shouldBeWhite, translate } from "utils/utils";

import "./styles.scss";

const ServiceIcon = ({ icon, label }: { icon: string; label: string }) => (
    <div className="service-icon">
        <Icon name={icon} />
        <h6>{label}</h6>
    </div>
);

class Project extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMobile: window.innerWidth <= 480,
        };

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        const { location } = this.props;

        this.handleResize();

        this.setState({ project: location?.state?.project || null });

        this.getData();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    async getData() {
        const { project } = this.state;
        const { dispatch, match } = this.props;

        dispatch(setLoader(true));

        let projectLink = project?.link || match?.params?.id;

        if (!projectLink) {
            notification.error({
                message: Strings.serverErrors.title,
                description: Strings.serverErrors.somethingWentWrong,
                placement: "bottomRight",
                duration: 5,
            });

            dispatch(push("/portfolio"));

            return;
        }

        try {
            const response = await API.get({
                url: Endpoints.uriPortfolio(projectLink),
            });

            if (response.ok) {
                const project = response.data.results;

                if (project.workInProgress || project.toBeReleased) {
                    dispatch(setLoader(false));
                    dispatch(push("/portfolio"));

                    return notification.error({
                        message: Strings.serverErrors.outOfBounds,
                        description: Strings.serverErrors.pageOutOfBounds,
                        placement: "bottomRight",
                        duration: 5,
                    });
                }

                this.setState({ project: response.data.results || null });
            }
        } catch (err) {
            console.log("Error", err);
        }

        dispatch(setLoader(false));
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 480 });
    }

    getService(service: string, index: number) {
        switch (service) {
            case "iOS":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="ios"
                        label={service}
                    />
                );
            case "Android":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="android"
                        label={service}
                    />
                );
            case "Backend":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="backend"
                        label={service}
                    />
                );
            case "Backoffice":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="backoffice"
                        label={service}
                    />
                );
            case "Design":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="design"
                        label={service}
                    />
                );
            case "React Native":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="reactjs"
                        label={service}
                    />
                );
            case "Swift":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="swift"
                        label={service}
                    />
                );
            case "Java":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="java"
                        label={service}
                    />
                );
            case "ReactJS":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="reactjs"
                        label={service}
                    />
                );
            case "AngularJS":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="angular"
                        label={service}
                    />
                );
            case "PHP":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="php"
                        label={service}
                    />
                );
            case "HTML5":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="html5"
                        label={service}
                    />
                );
            case "CSS":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="css"
                        label={service}
                    />
                );
            case "NodeJS":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="nodejs"
                        label={service}
                    />
                );
            case "Python":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="python"
                        label={service}
                    />
                );
            case "MongoDB":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="mongodb"
                        label={service}
                    />
                );
            case "PostgreSQL":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="postgre-sql"
                        label={service}
                    />
                );
            case "MySQL":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="mysql"
                        label={service}
                    />
                );
            case "Sketch":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="sketch"
                        label={service}
                    />
                );
            case "Illustrator":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="illustrator"
                        label={service}
                    />
                );
            case "Logo Design":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="illustrator"
                        label={service}
                    />
                );
            case "Photoshop":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="photoshop"
                        label={service}
                    />
                );
            case "Facebook":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="facebook-service"
                        label={service}
                    />
                );
            case "Google":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="google"
                        label={service}
                    />
                );
            case "Instagram":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="instagram"
                        label={service}
                    />
                );
            case "Flutter":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="flutter"
                        label={service}
                    />
                );
            case "Expo":
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="expo"
                        label={service}
                    />
                );
            default:
                return (
                    <ServiceIcon
                        key={`service_${service}_${index}`}
                        icon="reactjs"
                        label={service}
                    />
                );
        }
    }

    getIntegration(service: string, index: number) {
        const key = `integration_${service}_${index}`;

        console.log('SERVICE', service);

        switch (service.toLowerCase()) {
            case "facebook":
                return <Icon key={key} name="facebook-logo" />;
            case "fabric":
                return <Icon key={key} name="fabric" />;
            case "firebase":
                return <Icon key={key} name="firebase" />;
            case "google maps":
                return <Icon key={key} name="google-maps" />;
            case "mbway":
                return <Icon key={key} name="mbway" />;
            case "braintree":
                return <Icon key={key} name="braintree" />;
            case "invoice express":
                return <Icon key={key} name="invoicexpress" />;
            case "google cloud":
                return <Icon key={key} name="google-cloud" />;
            case "google plus":
                return <Icon key={key} name="google-plus" />;
            case "stripe":
                return <Icon key={key} name="stripe" />;
            case "aws":
                return <Icon key={key} name="aws" />;
            case "aws ses":
                return <SVGs.AwsSes/>;
            case "sendgrid":
                return <Icon key={key} name="sendgrid" />;
            case "twilio":
                return <Icon key={key} name="twilio" />;
            case "easypay":
                return <Icon key={key} name="easypay" />;
            case "apple login":
                return <Icon key={key} name="apple-login" />;
            default:
                return;
        }
    }

    renderIntegrations() {
        const { project, isMobile } = this.state;

        const carouselResponsive = {
            any: {
                breakpoint: { max: 4000, min: 0 },
                items: 1,
            },
        };

        if (
            !Array.isArray(project?.integrations) ||
            project?.integrations.length === 0
        )
            return;

        if (isMobile) {
            return (
                <div className="integrations-outer-container">
                    <section className="integrations-wrapper">
                        <h2>{Strings.portfolio.integrations}</h2>
                        <div className="integrations-container --mobile">
                            <Carousel
                                responsive={carouselResponsive}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                arrows={false}
                                autoPlay={true}
                                autoPlaySpeed={5000}
                                itemClass="carousel-item-padding"
                                showDots={true}
                            >
                                {project?.integrations?.map(
                                    (integration: string, index: number) => (
                                        <div
                                            key={`integration_mobile_index_${index}`}
                                            className="integration-mobile"
                                        >
                                            {this.getIntegration(integration, index)}
                                        </div>
                                    )
                                )}
                            </Carousel>
                        </div>
                    </section>
                </div>
            );
        }

        return (
            <div className="integrations-outer-container">
                <section className="integrations-wrapper">
                    <h2>{Strings.portfolio.integrations}</h2>
                    <div className="integrations-container">
                        {project?.integrations?.map((integration: string, index: number) =>
                            this.getIntegration(integration, index)
                        )}
                    </div>
                </section>
            </div>
        );
    }

    renderSections() {
        const { project } = this.state;

        return project?.sections?.map((section: any, index: number) =>
            this.renderSection(section, index + 1)
        );
    }

    renderSection(section: any, index: number) {
        const { project } = this.state;
        const style =
            index % 2 !== 0 ? { backgroundColor: project?.primaryColor } : {};

        let invertColor = false;
        if (shouldBeWhite(project?.primaryColor)) {
            invertColor = true;
        }

        return (
            <div key={`project_section_${index}`} style={style} className="project-outer-container">
                <section
                    className={`project-section${index % 2 !== 0 && invertColor ? " --white-text-color" : ""
                        }${index % 2 === 0 ? " --section-inverted" : ""}`}
                >
                    <div className="section-left-container">
                        <h3>{translate(section.title)}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: translate(section.description),
                            }}
                        />
                        {section.technologies?.length > 0 && (
                            <React.Fragment>
                                <h3 className="section-technologies">
                                    {Strings.portfolio.technologies}
                                </h3>
                                <div className="technologies-list">
                                    {section.technologies?.map((technology: any, index: number) =>
                                        this.getService(technology, index)
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="section-right-container">
                        <Device project={project} section={section} index={index} />
                    </div>
                </section>
            </div>
        );
    }

    render() {
        const { dispatch } = this.props;
        const { project } = this.state;

        const string =
            project?.duration === 1
                ? Strings.portfolio.month
                : Strings.portfolio.months;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`S4 Digital | ${project?.name ?? Strings.generic.loading
                        }`}</title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="project-wrapper">
                    <div className="splash-outer-container">
                        <section className="splash-wrapper">
                            <button
                                onClick={() => dispatch(push("/portfolio"))}
                                className="navigation-back"
                            >
                                <Icon name="back" />
                            </button>
                            <div className="main-title">
                                <h1>{project?.name ?? Strings.generic.loading}</h1>
                            </div>
                            <div className="dummy-container" />
                        </section>
                    </div>
                    <div className="about-project-outer-container">
                        <section className="about-project-container">
                            <Row gutter={[30, 20]}>
                                <Col xs={24} lg={18}>
                                    <h2>{Strings.portfolio.about}</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: translate(project?.smallDescription),
                                        }}
                                    />
                                    <div className="project-details">
                                        <Row gutter={[20, 20]}>
                                            <Col xs={24} md={12}>
                                                <h3>{Strings.portfolio.services}</h3>
                                                <div className="project-services">
                                                    {project?.services?.map(
                                                        (service: string, index: number) =>
                                                            this.getService(service, index)
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <h3>{Strings.portfolio.duration}</h3>
                                                <div className="project-duration">
                                                    <Icon name="clock" />
                                                    <p>
                                                        {Strings.formatString(string as string, {
                                                            time: project?.duration,
                                                        })}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={24} lg={6}>
                                    <h3>{Strings.portfolio.links}</h3>
                                    <div className="store-links">
                                        {Boolean(project?.linkAppStore) && (
                                            <button
                                                className="btn-borderless --btn-store"
                                                onClick={() => window.open(project.linkAppStore)}
                                            >
                                                <Icon name="apple-filled" />
                                                <div className="btn-store-text">
                                                    <p>{Strings.portfolio.downloadOn}</p>
                                                    <p>App Store</p>
                                                </div>
                                            </button>
                                        )}
                                        {Boolean(project?.linkGooglePlay) && (
                                            <button
                                                className="btn-borderless --btn-store"
                                                onClick={() => window.open(project.linkGooglePlay)}
                                            >
                                                <Icon name="google-play" />
                                                <div className="btn-store-text">
                                                    <p>{Strings.portfolio.downloadOn}</p>
                                                    <p>Google Play</p>
                                                </div>
                                            </button>
                                        )}
                                        {Boolean(project?.linkWeb) && (
                                            <button
                                                className="btn-borderless --btn-store"
                                                onClick={() => window.open(project.linkWeb)}
                                            >
                                                <Icon name="chrome" />
                                                <div className="btn-store-text">
                                                    <p>{Strings.portfolio.checkWebsite}</p>
                                                </div>
                                            </button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </div>
                    {this.renderIntegrations()}
                    {this.renderSections()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(Project);
