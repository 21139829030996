/**
 *
 * Sidebar
 *
 */

import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Strings from "utils/strings";
import "./styles.scss";

export class Sidebar extends React.PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: "/dashboard",
        };

        this.selectTab = this.selectTab.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    selectTab(url: String) {
        const { activeTab } = this.state;

        this.setState({ activeTab: activeTab === url ? "" : url });
    }

    goTo(url: String) {
        const clientWidth = document.body.clientWidth;
        this.setState({ activeTab: url });
        this.props.onMobile(clientWidth < 992);
        this.props.dispatch(push(`/${url}`));
    }

    render() {
        const { open } = this.props;

        return (
            <div className="SidebarWrapper">
                <div className={`SidebarContainer${open ? " open" : " closed"}`}>
                    <div className="SidebarHome">
                        <a className="SidebarLogoContainer" href="/">
                            <img
                                className="Logo"
                                alt="Logo"
                                src="https://www.sros.pt/static/media/logo512.6e235ac3.png"
                            />
                        </a>
                    </div>
                    <div className="SidebarContent">
                        <a
                            href={`${window.location.origin}/`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.goTo("");
                            }}
                            className="SidebarMenuObject"
                        >
                            <span>{Strings.sidebar.home}</span>
                        </a>
                        <a
                            href={`${window.location.origin}/`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.goTo("");
                            }}
                            className="SidebarMenuObject"
                        >
                            <span>Menu 2</span>
                        </a>
                        <a
                            href={`${window.location.origin}/`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.goTo("");
                            }}
                            className="SidebarMenuObject"
                        >
                            <span>Menu 3</span>
                        </a>
                        <a
                            href={`${window.location.origin}/`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.goTo("");
                            }}
                            className="SidebarMenuObject"
                        >
                            <span>Menu 4</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings,
    user: state.user,
    language: state.language,
});

export default connect(mapStateToProps)(Sidebar);
