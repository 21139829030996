import React from 'react';
// @ts-ignore
import { Popup } from 'react-typeform-embed';
import { translate } from 'utils/utils';
import { Checkbox, Col, Input, notification, Row } from 'antd';
import { Icon, SVGs } from 'components';
import { ClapSpinner } from 'react-spinners-kit';
import Strings from 'utils/strings';
import './styles.scss';
import { API, Endpoints } from 'utils/api';
import { setSurveys } from 'store/actions';
import { connect } from 'react-redux';

class ContactUs extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            surveys: null,
            form: null,
            submittingForm: false,
        };
    }

    async componentDidMount() {
        const { dispatch } = this.props;

        try {
            const response = await API.get({
                url: Endpoints.uriSurveys(),
            });

            if (response.ok) {
                dispatch(setSurveys(response.data.results.surveys));
                this.setState({ surveys: response.data.results.surveys });
            }
        } catch (err) {
            // do nothing
        }
    }

    async submitForm() {
        const { form } = this.state;

        if (!form?.name?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillName,
                placement: 'bottomRight',
                duration: 5,
            });
        } else if (!form?.email?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillEmail,
                placement: 'bottomRight',
                duration: 5,
            });
        } else if (!form?.phone.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillPhone,
                placement: 'bottomRight',
                duration: 5,
            });
        } else if (!form?.description.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillDescription,
                placement: 'bottomRight',
                duration: 5,
            });
        } else if (form?.description?.length < 50) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.moreInformation,
                placement: 'bottomRight',
                duration: 5,
            });
        }

        this.setState({ submittingForm: true });

        const response = await API.post({
            url: Endpoints.uriContactUs(),
            data: {
                name: form.name,
                email: form.email,
                phone: form.phone,
                country: form.country,
                message: form.description,
                nda: form.nda,
            },
        });

        if (response.ok) {
            this.setState({ submittingForm: false, form: null }, () => {
                notification.success({
                    message: Strings.home.contactForm,
                    description: Strings.home.requestSent,
                    placement: 'bottomRight',
                    duration: 5,
                });
            });
        } else {
            notification.error({
                message: Strings.serverErrors.title,
                description: Strings.home.requestNotPossible,
                placement: 'bottomRight',
                duration: 5,
            });
        }
    }

    render() {
        const { surveys, form, submittingForm } = this.state;

        return (
            <div className="contact-us-outer-container">
                <div className='s4-about-us-wrapper'>
                    <section className="s4-wrapper">
                        <div className="s4-title">
                            <Row>
                                <Col xs={24} md={24}>
                                    <Row>
                                        <h1>{Strings.home.s4AreaTitle}<span style={{ color: '#EB1E6E' }}>S4 Digital</span></h1>
                                    </Row>
                                    <Row>
                                        <p>{Strings.home.s4AreaText1}</p>
                                    </Row>
                                    <Row>
                                        <p>{Strings.home.s4AreaText2}</p>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <div className="estimate-project-container" style={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
                                        <button
                                            type="button"
                                            className="btn-primary --btn-icon"
                                            style={{ minHeight: 60, fontSize: 18 }}
                                            onClick={() => window.open('https://s4-digital.com')}
                                        >
                                            {Strings.home.s4AreaButton}
                                            <Icon name="back" className="--icon-rotate-180" />
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
                <section id="contact-us" className="contact-us-wrapper">
                    <div className="calculator-wrapper">
                        <Popup
                            id={translate(surveys)}
                            size={80}
                            autoClose={true}
                            onSubmit={() =>
                                notification.success({
                                    message: Strings.about.projectSimulation,
                                    description: Strings.about.sentSuccessfully,
                                    duration: 5,
                                    placement: 'bottomRight',
                                })
                            }
                        >
                            <button
                                type="button"
                                className="btn-calculator"
                            >
                                {Strings.home.estimateProject}
                                <SVGs.Calculator />
                            </button>
                        </Popup>
                    </div>
                    <div className="contact-us-form-wrapper">
                        <div className="contact-form">
                            <h3>{Strings.home.contactUs}</h3>
                            <h4>{Strings.home.feelFree}</h4>
                            <form
                                onSubmit={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (!submittingForm) {
                                        this.submitForm();
                                    }
                                }}>

                                <Row gutter={[20, 20]}>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="text"
                                            placeholder={Strings.home.fullname}
                                            value={form?.name || ''}
                                            required
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                this.setState((prevState: any) => ({ form: { ...prevState.form, name: value } }))
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="email"
                                            placeholder={Strings.home.email}
                                            value={form?.email || ''}
                                            required
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                this.setState((prevState: any) => ({ form: { ...prevState.form, email: value } }))
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="tel"
                                            placeholder={Strings.home.phone}
                                            value={form?.phone || ''}
                                            required
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                this.setState((prevState: any) => ({ form: { ...prevState.form, phone: value } }))
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Input
                                            type="text"
                                            placeholder={Strings.home.country}
                                            value={form?.country || ''}
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                this.setState((prevState: any) => ({ form: { ...prevState.form, country: value } }))
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Input.TextArea
                                            rows={5}
                                            placeholder={Strings.home.description}
                                            value={form?.description || ''}
                                            required
                                            onChange={(e: any) => {
                                                const value = e.target.value;
                                                this.setState((prevState: any) => ({ form: { ...prevState.form, description: value } }))
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Checkbox
                                    checked={form?.nda}
                                    style={{ paddingTop: 10 }}
                                    onChange={(e: any) => {
                                        const value = e.target.checked;
                                        this.setState((prevState: any) => ({ form: { ...prevState.form, nda: value } }))
                                    }}>
                                    {Strings.home.signNDA}
                                </Checkbox>
                                <button
                                    type="submit"
                                    className="btn-primary --btn-icon --btn-m50t"
                                >
                                    {Strings.home.startProject}
                                    {submittingForm ? (
                                        <ClapSpinner
                                            frontColor="#FFF"
                                            backColor="#FFF"
                                            size={18}
                                        />
                                    ) : (
                                        <Icon name="back" className="--icon-rotate-180" />
                                    )}
                                </button>
                            </form>
                        </div>
                        <div className="hr-container">
                            <div className="hr-photo" />
                            <p>Alexandra Ferreira</p>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(ContactUs);