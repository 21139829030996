import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon } from "components";
import { Checkbox, Col, Input, notification, Row } from "antd";
import { ClapSpinner } from "react-spinners-kit";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";
import Strings from "utils/strings";
import "./styles.scss";

const ACCEPTED_MIME_TYPE = [
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'application/pdf',
    'image/x-eps',
];

class JobOffer extends React.Component<any, any> {
    uploadInput: any;

    constructor(props: any) {
        super(props);

        this.state = {
            offer: null,
            form: null,
            submittingForm: false,
        };

        this.uploadInput = React.createRef();
    }

    componentDidMount() {
        this.getJobOffer();
    }

    async getJobOffer() {
        const { dispatch, match } = this.props;

        dispatch(setLoader(true));

        try {
            const response = await API.get({
                url: Endpoints.uriJobs(match?.params?.id),
            });

            if (response.ok) {
                this.setState({ offer: response.data.results.jobOportunities });
            }
        } catch (err) {
            console.log("Error", err);
        }

        dispatch(setLoader(false));
    }

    async submitForm() {
        const { form, offer } = this.state;

        if (!form?.name?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillName,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.email?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillEmail,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.phone.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillPhone,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.consent) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.work.acceptConsent,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.cv) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.work.uploadCVMandatory,
                placement: "bottomRight",
                duration: 5,
            });
        }

        this.setState({ submittingForm: true });

        const body = new FormData();

        body.append('name', form.name);
        body.append('email', form.email);
        body.append('phone', form.phone);
        body.append('region', form.region || '');
        body.append('offer', offer.title);
        body.append('availability', form.availability || '');
        body.append('wage', form.wage || '');
        body.append('consent', form.consent);
        body.append('linkedIn', form.linkedIn || '');

        body.append('cv', form.cv);

        const response = await API.post({
            url: Endpoints.uriApplyJob(),
            data: body,
        });

        if (response.ok) {
            this.setState({ submittingForm: false, form: null }, () => {
                notification.success({
                    message: Strings.work.jobsOpportunities,
                    description: Strings.work.requestSent,
                    placement: "bottomRight",
                    duration: 5,
                });
            });
        } else {
            notification.error({
                message: Strings.serverErrors.title,
                description: Strings.home.requestNotPossible,
                placement: "bottomRight",
                duration: 5,
            });
        }
    }

    render() {
        const { offer, form, submittingForm } = this.state;
        const { dispatch } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {`S4 Digital | ${offer?.name ?? Strings.generic.loading
                            }`}
                    </title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="job-offer-wrapper">
                    <div className="splash-outer-container">
                        <section className="splash-wrapper">
                            <button
                                onClick={() => dispatch(push("/work-with-us"))}
                                className="navigation-back"
                            >
                                <Icon name="back" />
                            </button>
                            <div className="main-title">
                                <h1>{Strings.work.jobsOpportunities}</h1>
                            </div>
                            <div className="dummy-container" />
                        </section>
                    </div>
                    <div className="offer-details-outer-container">
                        <section className="offer-details">
                            <h2>{offer?.title}</h2>
                            <h3>{Strings.work.location}</h3>
                            <p>{translate(offer?.location)}</p>
                            <h3>{Strings.work.offerDescription}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: translate(offer?.offerDescription),
                                }}
                            />
                        </section>
                    </div>
                    <div className="offer-apply-outer-container">
                        <section className="offer-apply">
                            <div className="offer-apply-container">
                                <h3>{Strings.work.apply}</h3>
                                <h4>{Strings.work.pleaseFill}</h4>
                                <form
                                    className="offer-apply-form"
                                    onSubmit={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        if (!submittingForm) {
                                            this.submitForm();
                                        }
                                    }}
                                >
                                    <Row gutter={[20, 20]}>
                                        <Col xs={24} md={18}>
                                            <Input
                                                value={form?.name}
                                                placeholder={Strings.fields.name}
                                                required
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, name: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Input
                                                value={form?.phone}
                                                placeholder={Strings.fields.phone}
                                                required
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, phone: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} md={18}>
                                            <Input
                                                value={form?.email}
                                                placeholder={Strings.fields.email}
                                                type="email"
                                                required
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, email: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Input
                                                value={form?.region}
                                                placeholder={Strings.fields.region}
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, region: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Input value={offer?.title} readOnly />
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Input
                                                value={form?.availability}
                                                placeholder={Strings.fields.availability}
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, availability: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Input
                                                value={form?.wage}
                                                placeholder={Strings.fields.desiredWage}
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, wage: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Input
                                                value={form?.linkedIn}
                                                placeholder={Strings.fields.linkedIn}
                                                onChange={(e: any) => {
                                                    const value = e.target.value;

                                                    this.setState((prevState: any) => ({
                                                        form: { ...prevState.form, linkedIn: value },
                                                    }));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="form-upload-cv">
                                        <label className="btn-upload --btn-icon">
                                            <input
                                                id="upload_cv_input"
                                                type="file"
                                                ref={this.uploadInput}
                                                accept={ACCEPTED_MIME_TYPE.join(',')}
                                                onChange={(e: any) => {
                                                    const file = e.target.files[0];


                                                    if (ACCEPTED_MIME_TYPE.includes(file.type)) {
                                                        this.setState((prevState: any) => ({
                                                            form: { ...prevState.form, cv: file },
                                                        }));
                                                    } else {
                                                        notification.warn({
                                                            message: Strings.work.unsupportedFileType,
                                                            description: Strings.work.unsupportedFileTypeText,
                                                            placement: "bottomRight",
                                                            duration: 5,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Icon name="upload" />
                                            Upload CV
                                        </label>
                                        {Boolean(form?.cv) && (
                                            <div className="form-uploaded-file">
                                                <Icon name="clip" />
                                                <p>{form?.cv?.name}</p>
                                                <Icon
                                                    name="bin --icon-clickable"
                                                    onClick={() => {
                                                        if (this.uploadInput) {
                                                            this.uploadInput.current.value = '';
                                                        }

                                                        this.setState((prevState: any) => ({
                                                            form: { ...prevState.form, cv: null },
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <Checkbox
                                        checked={form?.consent}
                                        onChange={(e: any) => {
                                            const value = e.target.checked;
                                            this.setState((prevState: any) => ({
                                                form: { ...prevState.form, consent: value },
                                            }));
                                        }}
                                    >
                                        {Strings.work.consent}
                                    </Checkbox>
                                    <div className="form-button-wrapper">
                                        <button
                                            type="submit"
                                            className="btn-primary --btn-icon --btn-m10t"
                                        >
                                            {Strings.work.applyNow}
                                            {submittingForm ? (
                                                <ClapSpinner frontColor="#FFF" backColor="#FFF" size={18} />
                                            ) : (
                                                <Icon name="back" className="--icon-rotate-180" />
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="other-offers">
                                <button
                                    onClick={() => {
                                        dispatch(push('/work-with-us', { moveTo: 'jobs' }));
                                    }}
                                >
                                    {Strings.work.seeOtherOffers}
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(JobOffer);
