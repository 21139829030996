import React from 'react'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setLoader } from 'store/actions';
import { Icon } from 'components';
import { API, Endpoints } from 'utils/api';
import { translate } from 'utils/utils';
import Strings from 'utils/strings';

import './styles.scss';

class Page extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            page: null,
        }
    }

    componentDidMount() {
        this.getData();
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        const { router } = this.props;

        if (router?.location?.pathname !== nextProps.router?.location?.pathname && nextProps.router?.location?.pathname?.includes('/pages/')) {
            this.getData(nextProps.match.params.id);
        }
    }

    async getData(id?: string) {
        const { dispatch, match } = this.props;

        dispatch(setLoader(true));

        const page = (id || match?.params?.id) === 'privacy' ? 'pp' : 'clutch';

        try {
            const response = await API.get({
                url: Endpoints.uriPages(page),
            });

            if (response.ok) {
                this.setState({ page: response.data.results.pages });
            }
        } catch (err) {
            console.log('Error', err as string);
        }

        dispatch(setLoader(false));
    }

    render() {
        const { page } = this.state;
        const { dispatch } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {`S4 Digital | ${translate(page?.title) || Strings.generic.loading
                            }`}
                    </title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="page-wrapper">
                    <div className="splash-outer-container">
                        <section className="splash-wrapper">
                            <button
                                onClick={() => dispatch(push("/"))}
                                className="navigation-back"
                            >
                                <Icon name="back" />
                            </button>
                            <div className="main-title">
                                <h1>{translate(page?.title) || Strings.generic.loading}</h1>
                            </div>
                            <div className="dummy-container" />
                        </section>
                    </div>
                    <div className="page-outer-container">
                        <section className="page-content">
                            <div dangerouslySetInnerHTML={{ __html: translate(page?.content) }} />
                        </section>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: any) => ({
    router: state.router,
    language: state.language,
});

export default connect(mapStateToProps)(Page);
