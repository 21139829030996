// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".portfolio-card-wrapper{display:flex;flex-direction:column}.portfolio-card-wrapper .project-thumbnail{position:relative;height:600px;width:100%;background-repeat:no-repeat;background-size:cover;background-position:center;border:1px solid #0d1117;cursor:pointer}.portfolio-card-wrapper .project-thumbnail.--project-disabled{cursor:default}.portfolio-card-wrapper .project-thumbnail .project-favicon{position:absolute;top:30px;left:30px;height:60px;width:60px;background-repeat:no-repeat;background-size:cover;background-position:center}@media screen and (max-width: 480px){.portfolio-card-wrapper .project-thumbnail{height:300px}}.portfolio-card-wrapper .project-description{background-color:#0d1117;padding:20px;min-height:200px}.portfolio-card-wrapper .project-description h4,.portfolio-card-wrapper .project-description p{color:#fff}.portfolio-card-wrapper .project-description h4{text-transform:uppercase;font-size:32px;line-height:40px}.portfolio-card-wrapper .project-description p{font-size:18px;line-height:24px}@media screen and (max-width: 480px){.portfolio-card-wrapper .project-description{min-height:150px}.portfolio-card-wrapper .project-description h4{font-size:24px;line-height:32px}.portfolio-card-wrapper .project-description p{font-size:14px;line-height:22px}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
