import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { PortfolioCard, SVGs } from "components";
import { Col, Row } from "antd";
import Strings from "utils/strings";
import "./styles.scss";
import { push } from "connected-react-router";
import { setLoader } from "store/actions";
import { API, Endpoints } from "utils/api";
import { translate } from "utils/utils";

class Portfolio extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            portfolio: [],
            typeProject: "all",
            isMobile: window.innerWidth < 992,
        };

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this.getData();

        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    async getData() {
        const { dispatch } = this.props;

        dispatch(setLoader(true));

        try {
            const response = await API.get({
                url: Endpoints.uriPortfolio(),
                params: {
                    sort: "createDate",
                    active: "true",
                },
            });

            if (response.ok) {
                this.setState({ portfolio: response.data.results || [] });
            }
        } catch (err) {
            console.log("Error", err);
        }

        dispatch(setLoader(false));
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth < 992 });
    }

    renderLists() {
        const { typeProject, portfolio, isMobile } = this.state;
        const { dispatch } = this.props;

        const filteredPortfolio = typeProject === 'all' ? portfolio : portfolio.filter((project: any) => project.type === typeProject || project.type === 'both');

        if (isMobile) {
            return filteredPortfolio.map((project: any, index: number) => (
                <Col key={`project_${project.name}`} xs={24}>
                    <PortfolioCard
                        name={project.name}
                        image={project.categoryImage}
                        thumbnail={project.icon}
                        description={translate(project.claim)}
                        workInProgress={project.workInProgress}
                        toBeReleased={project.toBeReleased}
                        onClick={() =>
                            dispatch(
                                push(`/portfolio/${project.link}`, { project })
                            )
                        }
                    />
                </Col>
            ));
        }

        return (
            <React.Fragment>
                <Col xs={24} lg={12}>
                    <Row gutter={[0, 25]}>
                        <Col xs={24}>
                            <h2>{Strings.portfolio.mainWork}</h2>
                        </Col>
                        {filteredPortfolio.map((project: any, index: number) => {
                            if (index % 2 !== 0) {
                                return (
                                    <Col key={`project_${project.name}`} xs={24}>
                                        <PortfolioCard
                                            name={project.name}
                                            image={project.categoryImage}
                                            thumbnail={project.icon}
                                            description={translate(project.claim)}
                                            workInProgress={project.workInProgress}
                                            toBeReleased={project.toBeReleased}
                                            onClick={() =>
                                                dispatch(
                                                    push(`/portfolio/${project.link}`, { project })
                                                )
                                            }
                                        />
                                    </Col>
                                );
                            }

                            return null;
                        })}
                    </Row>
                </Col>
                <Col xs={24} lg={12}>
                    <Row gutter={[0, 25]}>
                        {filteredPortfolio.map((project: any, index: number) => {
                            if (index % 2 === 0) {
                                return (
                                    <Col key={`project_${project.name}`} xs={24}>
                                        <PortfolioCard
                                            name={project.name}
                                            image={project.categoryImage}
                                            thumbnail={project.icon}
                                            description={translate(project.claim)}
                                            workInProgress={project.workInProgress}
                                            toBeReleased={project.toBeReleased}
                                            onClick={() =>
                                                dispatch(
                                                    push(`/portfolio/${project.link}`, { project })
                                                )
                                            }
                                        />
                                    </Col>
                                );
                            }

                            return null;
                        })}
                    </Row>
                </Col>
            </React.Fragment>
        );
    }

    render() {
        const { typeProject } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>S4 Digital | {Strings.header.portfolio}</title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="portfolio-wrapper">
                    <div className="splash-outer-container">
                        <div className="splash-background">
                            <SVGs.Waves />
                        </div>
                        <section className="splash-wrapper">
                            <div className="main-title">
                                <h1>{Strings.header.portfolio}</h1>
                            </div>
                            <div className="device-tabs">
                                <h3
                                    className={typeProject === "all" ? "--tab-active" : ""}
                                    onClick={() => {
                                        this.setState({ typeProject: "all" });
                                    }}
                                >
                                    {Strings.generic.all}
                                </h3>
                                <p> | </p>
                                <h3
                                    className={typeProject === "mobile" ? "--tab-active" : ""}
                                    onClick={() => {
                                        this.setState({ typeProject: "mobile" });
                                    }}
                                >
                                    {Strings.generic.mobile}
                                </h3>
                                <p> | </p>
                                <h3
                                    className={typeProject === "web" ? "--tab-active" : ""}
                                    onClick={() => {
                                        this.setState({ typeProject: "web" });
                                    }}
                                >
                                    {Strings.generic.web}
                                </h3>
                            </div>
                        </section>
                    </div>
                    <div className="portfolio-list-outer-container">
                        <section className="portfolio-list-container">
                            <Row gutter={[25, 25]}>
                                {this.renderLists()}
                            </Row>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(Portfolio);
