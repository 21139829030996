// Generic
export const SET_LOADER = 'SET_LOADER';
export const SET_TITLE = 'SET_TITLE';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_UPDATER = 'SET_UPDATER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SURVEYS = 'SET_SURVEYS';
export const SET_NAVIGATION = 'SET_NAVIGATION';
