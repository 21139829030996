import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import { Icon, SVGs } from "components";
import { Modal } from "antd";
import Strings from "utils/strings";
import "./styles.scss";

import image1 from "assets/images/team/1.jpg";
import image2 from "assets/images/team/2.jpg";
import image3 from "assets/images/team/3.jpg";
import image4 from "assets/images/team/4.jpg";
import image5 from "assets/images/team/5.jpg";
import image8 from "assets/images/team/8.jpg";
import image9 from "assets/images/team/9.jpg";
import image10 from "assets/images/team/10.jpg";
import image11 from "assets/images/team/11.jpg";
import image12 from "assets/images/team/12.jpg";
import image13 from "assets/images/team/13.jpg";
import image14 from "assets/images/team/14.jpg";
import image15 from "assets/images/team/15.jpg";
import image16 from "assets/images/team/16.jpg";
import image17 from "assets/images/team/17.jpg";
import image18 from "assets/images/team/18.jpg";
import image19 from "assets/images/team/19.jpg";
import image20 from "assets/images/team/20.jpg";


class AboutUs extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            openModal: false,
            selectedIndex: null,
            isMobile: window.innerWidth <= 768,
        };


        this.handleResize = this.handleResize.bind(this);
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    selectPreviewImage = (index: number) => {
        this.setState({ openModal: true, selectedIndex: index });
    };

    renderGridImage(image: any, className: string, index: number, mobile?: boolean) {
        return (
            <div
                onClick={() => this.selectPreviewImage(index)}
                className={className}
                style={{ backgroundImage: `url('${image}')` }}
            >
                {!mobile && (
                    <div className="preview-mask">
                        <Icon name="magnifying-glass" />
                    </div>
                )}
            </div>
        )
    }

    renderTeam() {
        const { openModal, isMobile } = this.state;
        const carouselResponsive = {
            any: {
                breakpoint: { max: 4000, min: 0 },
                items: 1,
            },
        };

        if (isMobile) {
            return (
                <div className="team-outer-container">
                    <section className="team-wrapper">
                        <h2>{Strings.about.team}</h2>
                        <div className="team-image-gallery">
                            <Carousel
                                responsive={carouselResponsive}
                                swipeable={true}
                                draggable={true}
                                infinite={true}
                                arrows={false}
                                autoPlay={!openModal}
                                autoPlaySpeed={5000}
                                showDots={true}
                                itemClass="carousel-item-padding"
                            >
                                {this.renderGridImage(image1, 'item-mobile', 0, true)}
                                {this.renderGridImage(image20, 'item-mobile', 1, true)}
                                {this.renderGridImage(image17, 'item-mobile', 2, true)}
                                {this.renderGridImage(image3, 'item-mobile --img-adjust-top-mobile', 3, true)}
                                {this.renderGridImage(image19, 'item-mobile', 4, true)}
                                {this.renderGridImage(image2, 'item-mobile --img-adjust-top-mobile', 5, true)}
                                {this.renderGridImage(image10, 'item-mobile', 6, true)}
                                {this.renderGridImage(image16, 'item-mobile', 7, true)}
                                {this.renderGridImage(image11, 'item-mobile', 8, true)}
                                {this.renderGridImage(image12, 'item-mobile', 9, true)}
                                {this.renderGridImage(image13, 'item-mobile', 10, true)}
                                {this.renderGridImage(image14, 'item-mobile', 11, true)}
                                {this.renderGridImage(image15, 'item-mobile', 12, true)}
                                {this.renderGridImage(image18, 'item-mobile', 13, true)}
                                {this.renderGridImage(image5, 'item-mobile', 14, true)}
                                {this.renderGridImage(image4, 'item-mobile', 15, true)}
                                {this.renderGridImage(image8, 'item-mobile', 16, true)}
                                {this.renderGridImage(image9, 'item-mobile', 17, true)}
                            </Carousel>
                        </div>
                    </section>
                </div>
            )
        }

        return (
            <div className="team-outer-container">
                <section className="team-wrapper">
                    <h2>{Strings.about.team}</h2>
                    <div className="team-image-gallery">
                        <Carousel
                            responsive={carouselResponsive}
                            swipeable={true}
                            draggable={true}
                            infinite={true}
                            arrows={false}
                            autoPlay={!openModal}
                            autoPlaySpeed={5000}
                            showDots={true}
                            itemClass="carousel-item-padding"
                        >
                            <div className="team-masonry-container">
                                {this.renderGridImage(image1, 'item-1-2', 0)}
                                {this.renderGridImage(image20, 'item-2-1', 1)}
                                {this.renderGridImage(image17, 'item-1-1', 2)}
                                {this.renderGridImage(image3, 'item-1-2', 3)}
                                {this.renderGridImage(image19, 'item-2-2', 4)}
                                {this.renderGridImage(image2, 'item-1-1 --img-adjust-top', 5)}
                            </div>
                            <div className="team-masonry-container">
                                {this.renderGridImage(image10, 'item-1-1', 6)}
                                {this.renderGridImage(image16, 'item-1-1', 7)}
                                {this.renderGridImage(image11, 'item-1-2', 8)}
                                {this.renderGridImage(image12, 'item-1-1', 9)}
                                {this.renderGridImage(image13, 'item-1-1', 10)}
                                {this.renderGridImage(image14, 'item-1-1', 11)}
                                {this.renderGridImage(image15, 'item-2-1', 12)}
                            </div>
                            <div className="team-masonry-container">
                                {this.renderGridImage(image18, 'item-2-1', 13)}
                                {this.renderGridImage(image5, 'item-1-2', 14)}
                                {this.renderGridImage(image4, 'item-2-1', 15)}
                                {this.renderGridImage(image8, 'item-1-1', 16)}
                                {this.renderGridImage(image9, 'item-2-1', 17)}
                            </div>
                        </Carousel>
                    </div>
                </section>
            </div>
        );
    }

    renderPreview() {
        const { openModal, selectedIndex } = this.state;

        if (selectedIndex == null) return;

        const carouselResponsive = {
            any: {
                breakpoint: { max: 4000, min: 0 },
                items: 1,
            },
        };

        const images = [
            <div
                key="image_1"
                className="preview-image"
                style={{ backgroundImage: `url('${image1}')` }}
            />,
            <div
                key="image_2"
                className="preview-image"
                style={{ backgroundImage: `url('${image20}')` }}
            />,
            <div
                key="image_3"
                className="preview-image"
                style={{ backgroundImage: `url('${image17}')` }}
            />,
            <div
                key="image_4"
                className="preview-image"
                style={{ backgroundImage: `url('${image3}')` }}
            />,
            <div
                key="image_5"
                className="preview-image"
                style={{ backgroundImage: `url('${image19}')` }}
            />,
            <div
                key="image_6"
                className="preview-image"
                style={{ backgroundImage: `url('${image2}')` }}
            />,
            <div
                key="image_7"
                className="preview-image"
                style={{ backgroundImage: `url('${image10}')` }}
            />,
            <div
                key="image_8"
                className="preview-image"
                style={{ backgroundImage: `url('${image16}')` }}
            />,
            <div
                key="image_9"
                className="preview-image"
                style={{ backgroundImage: `url('${image11}')` }}
            />,
            <div
                key="image_10"
                className="preview-image"
                style={{ backgroundImage: `url('${image12}')` }}
            />,
            <div
                key="image_11"
                className="preview-image"
                style={{ backgroundImage: `url('${image13}')` }}
            />,
            <div
                key="image_12"
                className="preview-image"
                style={{ backgroundImage: `url('${image14}')` }}
            />,
            <div
                key="image_13"
                className="preview-image"
                style={{ backgroundImage: `url('${image15}')` }}
            />,
            <div
                key="image_14"
                className="preview-image"
                style={{ backgroundImage: `url('${image18}')` }}
            />,
            <div
                key="image_15"
                className="preview-image"
                style={{ backgroundImage: `url('${image5}')` }}
            />,
            <div
                key="image_16"
                className="preview-image"
                style={{ backgroundImage: `url('${image4}')` }}
            />,
            <div
                key="image_17"
                className="preview-image"
                style={{ backgroundImage: `url('${image8}')` }}
            />,
            <div
                key="image_18"
                className="preview-image"
                style={{ backgroundImage: `url('${image9}')` }}
            />,
        ];

        return (
            <Modal
                title={null}
                footer={null}
                width="100%"
                visible={openModal}
                centered
                className="preview-modal"
                maskStyle={{ backgroundColor: "#000000d9" }}
                onCancel={() =>
                    this.setState({ openModal: false, selectedIndex: null })
                }
            >
                <Carousel
                    responsive={carouselResponsive}
                    swipeable={true}
                    draggable={true}
                    infinite={true}
                    arrows={true}
                    autoPlay={false}
                    showDots={false}
                >
                    {images
                        .slice(selectedIndex, images.length)
                        .map((image: any) => image)}
                    {selectedIndex > 1 &&
                        images.slice(0, selectedIndex).map((image: any) => image)}
                </Carousel>
            </Modal>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>S4 Digital | {Strings.header.whatWeDo}</title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="about-us-wrapper">
                    <div className="splash-outer-container">
                        <div className="splash-background">
                            <SVGs.Waves />
                        </div>
                        <section className="splash-wrapper">
                            <div className="main-title">
                                <h1>{Strings.header.aboutUs}</h1>
                                <h3>{Strings.about.subTitle}</h3>
                            </div>
                        </section>
                    </div>
                    <div className="about-us-outer-container">
                        <section className="about-us">
                            <h2>{Strings.header.aboutUs}</h2>
                            <p>{Strings.about.aboutUsText1}</p>
                            <p>{Strings.about.aboutUsText2}</p>
                        </section>
                    </div>
                    <div className="why-us-outer-container">
                        <section className="why-us">
                            <h2>{Strings.about.whyUs}</h2>
                            <p>{Strings.about.whyUsText1}</p>
                            <p>{Strings.about.whyUsText2}</p>
                            <div className="numbers-container">
                                <h3>{Strings.about.someCount}</h3>
                                <p>{Strings.about.someCountText}</p>
                                <div className="number-values">
                                    <div className="number-element">
                                        <p className="number-element-value">500+</p>
                                        <p className="number-element-label">
                                            {Strings.about.projectConsultations}
                                        </p>
                                    </div>
                                    <div className="separator" />
                                    <div className="number-element">
                                        <p className="number-element-value">10+</p>
                                        <p className="number-element-label">
                                            {Strings.about.yearsExperience}
                                        </p>
                                    </div>
                                    <div className="separator" />
                                    <div className="number-element">
                                        <p className="number-element-value">70+</p>
                                        <p className="number-element-label">
                                            {Strings.about.developedProjects}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {this.renderTeam()}
                    {this.renderPreview()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(AboutUs);
