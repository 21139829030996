import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
	/* cSpell:enable */
    /* cSpell.language:en */
	en: {
		// Generic
		generic: {
			profile: 'Profile',
			save: 'Save',
            all: 'All',
            mobile: 'Mobile',
            web: 'Web',
            loading: 'Loading...',
		},

		fields: {
            name: 'Name *',
            email: 'E-mail *',
            phone: 'Phone *',
            region: 'Region',
            availability: 'Availability',
            desiredWage: 'Desired Wage',
            linkedIn: 'LinkedIn Profile',
        },

		sidebar: {
			home: 'Home',
		},

		language: {
			header: 'Language',
			defaultLanguage: 'Default Language',
			languageNotActive: 'Save the changes before changing the default language.',
			portuguese: 'Portuguese',
			english: 'English',
			german: 'German',
			french: 'French',
			spanish: 'Spanish',
		},

		// Screens
		notFound: {
			header: 'Not Found',
		},

        header: {
            home: 'Home',
            aboutUs: 'About Us',
            whatWeDo: 'What We Do',
            process: 'Process',
            portfolio: 'Portfolio',
            workWithUs: 'Work With Us',
            contactUs: 'Contact Us',
            privacy: 'Privacy Policy',
            usOnClutch: 'Us on Clutch',
        },

        home: {
            estimateProject: 'Estimate your project',
            contactSales: 'Contact Sales',
            trustedBy: 'We are trusted by',
            workWithUs: 'Why you should work with us',
            createVision: 'We create a vision with the client and for the client',
            createVisionText: 'From the beginning of the creation process, we seek to build on the initial idea brought by the client, applying our industry know-how to guide the client through the project in a structured and gradual way.',
            createVisionText2: 'Regular meetings ensure we never lose sight of clients\' end-goals and help us add maximum value to the project.',
            punctual: 'We are punctual and objective',
            punctualText: 'From the beginning of the creation process, we seek to build on the initial idea brought by the client, applying our industry know-how to guide the client through the project in a structured and gradual way. Regular meetings ensure we never lose sight of clients’ end-goals and help us add maximum value to the project.',
            supportCompanies: 'We support companies and entrepeneurs',
            supportCompaniesText: 'In each project that we undertake, we seek to understand the end-user of our solution to ensure our products pass the ‘usability test’ and meet the required needs. We research the markets in which our clients operate to ensure our solutions deliver key benefits.',
            projectManagement: 'We use the best project management practices',
            projectManagementText: 'In our development process we follow the best project management practices, with an emphasis on Scrum, respecting the individuality of each project. We take client communication very seriously and understand that it is key to delivering a successful project. We have regular meetings with the client throughout the entire life cycle.',
            whatWeDo: 'What we do',
            uiuxDesign: 'UI/UX Design',
            mobileDevelopment: 'Mobile Development',
            webDevelopment: 'Web Development',
            backendDevelopment: 'Backend Development',
            dataExpertise: 'Data Expertise',
            softwareIntegration: 'Software Integration',
            devOps: 'Devops',
            projectManagementSingle: 'Project Management',
            outsourcing: 'Outsourcing',
            clientsSay: 'What our clients say',
            contactUs: 'Contact Us',
            feelFree: 'Feel free to contact us with your app idea',
            signNDA: 'Sign NDA',
            startProject: 'Start your project now',
            fullname: 'Full Name *',
            email: 'E-mail *',
            phone: 'Phone *',
            country: 'Country',
            description: 'Solution Description *',
            fillName: 'Please fill your full name.',
            fillEmail: 'Please fill your e-mail.',
            fillPhone: 'Please fill your phone number.',
            fillDescription: 'Please fill a description of your idea.',
            moreInformation: 'Please give us a more elaborate idea description.',
            contactForm: 'Contact Form',
            requestSent: 'Contact request has been sent successfully.',
            requestNotPossible: 'It was not possible to connect to the server. Please try again later.',
            title: 'Custom web and mobile development applications',
            titleS4: 'Dev2Grow is now ',
            subTitle: 'We develop mobile and applications at an efficient cost by following the best project management practices while never losing sight of our goal to present the finest interface to the user.',
            s4AreaTitle: 'Getting to know ',
            s4AreaText1: `We're a global consulting company specializing in Smart Sourcing, Software Solutions and Program Management, enabling successful digital transformation through a talented team and advanced technology.`,
            s4AreaText2: `We help bridge the gap between strategy and execution, creating secure and effective short-term solutions for full-scale adoption. Our value proposition offers a balanced approach to business and technology consulting for a seamless transformation journey.`,
            s4AreaButton: `See all our services`,
        },

		errors: {
			invalidArgs: 'Invalid or missing arguments',
			invalidFields: 'Invalid or missing fields',
			emailIsInvalid: 'Email format is not valid',
			fillEmail: 'Please input your email',
			fillPassword: 'Please input your password',
			pleaseFillFormCorrectly: 'Please fill the form correctly',
			fillField: 'This field is mandatory',
			notSupportedFile: 'File type not supported',
			nameIsEmpty: 'Please fill name field',
			passwordsDontMatch: 'Fields \'New password\' and \'Confirm password\' don\'t match',
			emailIsEmpty: 'Please fill email field',
			fileTooBigorInvalid: 'File size is larger than 8MB or file is corrupt',
            invalidFormat: 'Invalid format.',
		},

		placeholders: {
			name: 'your name',
			email: 'your@email.com',
			phoneNumber: '+351265000000',
			language: 'Select a language option',
			role: 'Select a role',
			currentPassword: 'actual password',
			newPassword: 'new password',
			confirmPassword: 'confirm new password',
			newEmail: 'new@email.com',
			page: 'page',
			titlePage: 'Page Title',
			link: 'https://www.google.com/',
			profilePic: 'Profile Picture',
			password: 'your password',
		},

		serverErrors: {
			title: 'Internal Server Error',
			subTitle: 'It\'s not you, it\'s us!',
			refresh: 'Go Back to Previous Page',
			backToHome: 'Go to Home',
			accountNotConfirmed: 'Account not confirmed.',
			notFound: 'Account does not exist.',
			invalidCredentials: 'Account does not exist or credentials are invalid.',
            somethingWentWrong: 'Something went wrong.',
            outOfBounds: 'Out of Bounds',
            pageOutOfBounds: 'This page is out of bounds and you have no permission to see it.',
		},

        portfolio: {
            mainWork: 'Our main work that we have done',
            project: 'Project',
            about: 'About the project',
            services: 'Services',
            duration: 'Project Duration',
            links: 'Project Links',
            month: '{time} month',
            months: '{time} months',
            downloadOn: 'Download on the',
            checkWebsite: 'Check Website',
            integrations: 'Integrations',
            technologies: 'Technologies',
            workInProgress: 'Work In Progress',
            toBeReleased: 'To Be Released',
        },

        whatWeDo: {
            subTitle: 'Given our development methodology, project development takes an average of 4-5 months',
            ourTechnologies: 'Our Technologies',
            mobileTechnologies: 'Mobile Technologies',
            mobileTechnologiesText: 'With a team specialized in mobile development on React Native or Flutter framework, we realize mobile applications with a tailor-made design to the customer, giving the end user a native app experience because the code is natively compiled. This optimization results in a substantial reduction in development time and the end result of an app with a usage and performance experience many times superior to the native one.',
            webTechnologies: 'Web Technologies',
            webTechnologiesText: 'For web application development, we use ReactJS or VueJS which are a JavaScript component libraries that allows you to create user interfaces with an appealing and high performance design, thus being quite flexible to develop any web frontend project. For common Web and Mobile projects, we typically use Expo in order to optimize development time and minimize time to market.',
            backendTechnologies: 'Backend Technologies',
            backednTechnologiesText: 'All of our applications, web or mobile, connect to a database through an API. Mostly implemented through NodeJS technology, APIs that can be public or private, and are usually the engine of all processes and operations performed in applications.',
            theProcess: 'The Process',
            theProcessText: 'What you can expect from the moment you contact us until the application is finished',
            projectAssessment: 'Project Assessment',
            projectAssessmentText: 'After we understand the concept and functionalities you want for the application, we give you a general idea of values and project deadlines. We offer our experience so that together we can improve the project.',
            designUiUx: 'Design UI/UX',
            designUiUxText: 'After a kick off meeting, we will design the flow and visuals of the application. We will send you a navigable prototype based on the design we created.',
            development: 'Development',
            developmentText1: 'Once you approve the design and flow we created, we will move on to the development phase.',
            developmentText2: 'In project management we will divide the project into Sprints (phases) so that you can test the application along the way. This will allow you to make corrections and improvements.',
            testing: 'Testing',
            testingText: 'At the end of development, we will go into the final testing phase, with our QA team testing the application to deliver it to you with a minimum of bugs, and there is also a battery of tests on your side to fix as many bugs as possible.',
            deploy: 'Deploy',
            deployText1: 'After fixing the detected errors, we will put the app on the stores in the case of a mobile app or put it live if it is a web app.',
            deployText2: 'After the application is complete, you can count on us for support and maintenance, which includes developing new features as well as adapting the app to legal requirements.',
            deployText3: 'For bugs fix, please note that we give 24 months warranty.',
        },

        about: {
            subTitle: 'We are professional but very relaxed',
            aboutUsText1: 'We are a young dynamic company which was created from resources that have over 20 years of IT experience, in addition to our senior team members we scout and recruit top young talent thanks to a strong partnership we established with the Polytechnic Institute of Setúbal.',
            aboutUsText2: 'We specialize in web and mobile app development and are very proud in what we do, we are known for the quality of our work as well as keeping exceptional communication with our clients which is key to the success of any project.',
            whyUs: 'Why Us',
            whyUsText1: 'S4 Digital, via acquisition of Dev2Grow, is a leading mobile and web application development company.',
            whyUsText2: 'Give us an idea and together we will work to deliver it.',
            someCount: 'Some stats that matter',
            someCountText: 'Our achievements depicted in numbers',
            projectConsultations: 'Project Consultations',
            yearsExperience: 'Years of Experience',
            developedProjects: 'Developed Projects',
            team: 'Our Team',
            projectSimulation: 'Project Simulation',
            sentSuccessfully: 'Simulation has been sent successfully.',
        },

        work: {
            subTitle: 'You can expect a view of the Sado river and the Arrábida mountains in our offices or work on your sofa',
            workWithUs: 'Work With Us',
            workWithUsText: 'We provide a relaxed working environment and opportunity for professional and personal growth. Send your application and come meet us to discover all that Dev2Grow can provide you. Much more than a career, we build your future and your happiness',
            friendly: 'Friendly Work Environment',
            drinks: 'Drinks',
            tools: 'Work Tools',
            fun: 'Fun Room',
            jobsOpportunities: 'Job Opportunities',
            days: 'days',
            day: 'day',
            ago: 'ago',
            hours: 'hours',
            hour: 'hour',
            applyNow: 'Apply Now',
            apply: 'Apply',
            recruitmentProcess: 'Recruitment Process',
            sendCV: 'Send CV',
            sendCVText: 'Apply for a job offer or send us your CV',
            contact: 'Contact',
            contactText: 'We will contact you by e-mail or phone to schedule a meeting',
            interview: 'Interview',
            interviewText: 'The interview is a friendly chat to get to know each other',
            task: 'Task',
            taskText: 'It\'s nothing complicated, just to better understand your capabilities',
            onboarding: 'Onboarding',
            onboardingText: 'Welcome to our team',
            joinTeam: 'Join Out Team!',
            joinTeamText: 'If there are no openings that interest you, send us your CV. Talented and happy people are always welcome!',
            uploadCV: 'Upload your CV',
            location: 'Location',
            offerDescription: 'Offer Description',
            requirements: 'Requirements',
            pleaseFill: 'Please fill out the form below to apply to this offer',
            consent: 'I consent to my data being collected and used for internal recruitment purposes',
            requestSent: 'Your application has been registered successfully.',
            acceptConsent: 'You need to agree on consenting your information to be processed.',
            seeOtherOffers: 'See Other Offers',
            unsupportedFileType: 'Unsupported File Type',
            unsupportedFileTypeText: 'The file you selected has an unsupported extension. Please upload an image or PDF.',
            uploadCVMandatory: 'You must upload a CV before submitting your application.',
            selectJob: 'Applying to *',
            mustSelectJob: 'You should select a job to apply to.',
        },
	},
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
        generic: {
			profile: 'Perfil',
			save: 'Guardar',
            all: 'Todos',
            mobile: 'Mobile',
            web: 'Web',
            loading: 'Loading...',
		},

		fields: {
            name: 'Nome *',
            email: 'E-mail *',
            phone: 'Telemóvel *',
            region: 'Região',
            availability: 'Disponibilidade',
            desiredWage: 'Salário Pretendido',
            linkedIn: 'Perfil Linkedin',
        },

		sidebar: {
			home: 'Home',
		},

		language: {
			header: 'Idioma',
			defaultLanguage: 'Idioma base',
			languageNotActive: 'Save the changes before changing the default language.',
			portuguese: 'Português',
			english: 'Inglês',
			german: 'German',
			french: 'French',
			spanish: 'Spanish',
		},

		// Screens
		notFound: {
			header: 'Not Found',
		},

        header: {
            home: 'Home',
            aboutUs: 'Sobre Nós',
            whatWeDo: 'O Que Fazemos',
            process: 'Processo',
            portfolio: 'Portfolio',
            workWithUs: 'Oportunidades',
            contactUs: 'Contacte-nos',
            privacy: 'Política de Privacidade',
            usOnClutch: 'Nós na Clutch',
        },

        home: {
            estimateProject: 'Estimar o seu projeto',
            contactSales: 'Contactar o Dep. Vendas',
            trustedBy: 'Confiam em nós',
            workWithUs: 'Porque deve trabalhar connosco',
            createVision: 'Pensamos o projeto com o cliente e para o cliente',
            createVisionText: 'Procuramos desde o início do processo de criação, imergir na ideia inicial trazida pelo cliente e desenvolvê-la de forma estruturada e gradual.',
            createVisionText2: 'Através de reuniões periódicas com o cliente, levantamos não só as suas necessidades, mas procuramos com o nosso know-how ajudar o cliente a encontrar a solução ideal com o objetivo de agregar o máximo de valor ao projeto.',
            punctual: 'Somos pontuais e objetivos',
            punctualText: 'Gostamos de apresentar aos clientes soluções práticas e objetivas, com um design único e elegante. Para isso, iremos usar as ferramentas mais adequadas para os projetos de modo a que em conjunto com as melhores práticas de gestão de projetos consigamos entregar os projetos nos prazos definidos inicialmente com o cliente.',
            supportCompanies: 'Apoiamos empresas e empreendedores',
            supportCompaniesText: 'Em cada projeto realizado procuramos entender o público alvo que irá utilizar a solução, quais as suas necessidades explícitas e implícitas para assim trabalhar toda a usabilidade da solução a fornecer. Também pesquisamos o mercado externo e interno dos clientes e quais são os benefícios que a solução irá trazer.',
            projectManagement: 'Utilizamos as melhores práticas de gestão de projetos',
            projectManagementText: 'No nosso processo de desenvolvimento, aplicamos as melhores práticas de gestão de projetos, com ênfase no Scrum, respeitando a particularidade de cada projeto. A comunicação com o cliente é um fator primordial para o sucesso de qualquer projeto, realizado reuniões de projeto de forma periódica durante todo o ciclo de vida do projeto.',
            whatWeDo: 'O que fazemos',
            uiuxDesign: 'Design UI/UX',
            mobileDevelopment: 'Desenvolvimento Mobile',
            webDevelopment: 'Desenvolvimento Web',
            backendDevelopment: 'Desenvolvimento Backend',
            dataExpertise: 'Especialização em Dados',
            softwareIntegration: 'Integração de Software',
            devOps: 'Devops',
            projectManagementSingle: 'Gestão de Projeto',
            outsourcing: 'Outsourcing',
            clientsSay: 'O que os nossos clientes dizem',
            contactUs: 'Contacte-nos',
            feelFree: 'Entre em contacto connosco com a sua ideia de aplicação',
            signNDA: 'Assinar Acordo de Confidencialidade',
            startProject: 'Inicie o seu projeto',
            fullname: 'Nome *',
            email: 'E-mail *',
            phone: 'Telefone *',
            country: 'País',
            description: 'Descrição da Solução *',
            fillName: 'Por favor, preencha o seu nome.',
            fillEmail: 'Por favor, preencha o seu e-mail.',
            fillPhone: 'PPor favor, preencha o seu n.º de telefone.',
            fillDescription: 'Por favor, preencha uma descrição da sua ideia.',
            moreInformation: 'Por favor, dê-nos uma descrição mais elaborada da ideia.',
            contactForm: 'Formulário de Contacto',
            requestSent: 'O pedido de contacto foi enviado com sucesso.',
            requestNotPossible: 'Não foi possível a ligação ao servidor. Por favor, tente novamente mais tarde.',
            title: 'Desenvolvimento web e mobile à medida',
            titleS4: 'Dev2Grow agora é ',
            subTitle: 'Desenvolvemos aplicações móveis e web com um custo total reduzido, sempre com o cuidado de apresentar a melhor interface para o utilizador, pois seguimos as melhores práticas de gestão de projeto.',
            s4AreaTitle: 'Conhecer a ',
            s4AreaText1: `Somos uma empresa de consultoria global especializada em Smart Sourcing, Soluções de Software e Gestão de Programas, permitindo uma transformação digital bem sucedida através de uma equipa talentosa e tecnologia avançada.`,
            s4AreaText2: `Ajudamos a colmatar o fosso entre a estratégia e a execução, criando soluções seguras e eficazes a curto prazo para uma adopção em grande escala. A nossa proposta de valor oferece uma abordagem equilibrada à consultoria de negócios e tecnologia para uma jornada de transformação perfeita.`,
            s4AreaButton: `Ver todos os nossos serviços`,
        },

		errors: {
			invalidArgs: 'Argumentos inválidos ou inexistentes',
			invalidFields: 'Campos inválidos ou em falta',
			emailIsInvalid: 'O formato de e-mail não é válido',
			fillEmail: 'Por favor introduza o seu e-mail',
			fillPassword: 'Por favor introduza a sua password',
			pleaseFillFormCorrectly: 'Por favor, preencha correctamente o formulário',
			fillField: 'Este campo é obrigatório',
			notSupportedFile: 'FTipo de ficheiro não suportado',
			nameIsEmpty: 'Por favor preencha o campo do nome',
			passwordsDontMatch: 'Os campos \'Nova password\' e \'Confirmar password\' não coincidem',
			emailIsEmpty: 'Por favor preencha o campo de e-mail',
			fileTooBigorInvalid: 'O tamanho do ficheiro é maior do que 8MB ou o ficheiro é corrupto',
            invalidFormat: 'Formato Inválido.',
		},

		placeholders: {
			name: 'o seu nome',
			email: 'nome@email.com',
			phoneNumber: '+351265000000',
			language: 'SSeleccione uma opção de idioma',
			role: 'Seleccione uma função',
			currentPassword: 'password atual',
			newPassword: 'nova password',
			confirmPassword: 'confirme a nova password',
			newEmail: 'novo@email.com',
			page: 'página',
			titlePage: 'Título da página',
			link: 'https://www.google.com/',
			profilePic: 'Foto de perfil',
			password: 'a sua password',
		},

		serverErrors: {
			title: 'Internal Server Error',
			subTitle: 'Não é você, somos nós!',
			refresh: 'Voltar à Página Anterior',
			backToHome: 'Go to Home',
			accountNotConfirmed: 'Account not confirmed.',
			notFound: 'Account does not exist.',
			invalidCredentials: 'Account does not exist or credentials are invalid.',
            somethingWentWrong: 'Something went wrong.',
            outOfBounds: 'Out of Bounds',
            pageOutOfBounds: 'This page is out of bounds and you have no permission to see it.',
		},

        portfolio: {
            mainWork: 'O nosso principal trabalho que temos desenvolvido',
            project: 'Projeto',
            about: 'Sobre o Projeto',
            services: 'Serviços',
            duration: 'Duração do Projeto',
            links: 'Links para o Projeto',
            month: '{time} mês',
            months: '{time} meses',
            downloadOn: 'Download on the',
            checkWebsite: 'Check Website',
            integrations: 'Integrações',
            technologies: 'Tecnologias',
            workInProgress: 'Work In Progress',
            toBeReleased: 'To Be Released',
        },

        whatWeDo: {
            subTitle: 'Dada a nossa metodologia de desenvolvimento, o desenvolvimento de projectos demora em média 4-5 meses',
            ourTechnologies: 'As Nossas Tecnologias',
            mobileTechnologies: 'Mobile Technologies',
            mobileTechnologiesText: 'Com uma equipa especializada em desenvolvimento de aplicações móveis na framework React Native ou Flutter, realizamos aplicações móveis com um design pensado e realizado à medida do cliente, fornecendo ao utilizador a experiência de uma app nativa, pois o código é compilado nativamente. Esta optimização resulta, numa substancial redução no tempo de desenvolvimento e com o resultado final de uma app com uma experiência de utilização e performance muitas vezes superior ao nativo.',
            webTechnologies: 'Web Technologies',
            webTechnologiesText: 'Para o desenvolvimento de aplicações web, utilizamos ReactJS que é uma biblioteca de componentes em JavaScript que permite criar interfaces de utilizador com um design apelativo e com uma elevada performance, sendo assim bastante flexível para desenvolver qualquer projeto de frontend web. Para projetos comuns em Web e Mobile, normalmente, utilizamos Expo, de modo a optimizar o tempo de desenvolvimento e minimizar o time to market.',
            backendTechnologies: 'Backend Technologies',
            backednTechnologiesText: 'Todas as nossas aplicações, web ou mobile, ligam a uma base de dados através de uma API. Maioritariamente realizadas através da tecnologia NodeJS, as API’s que podem ser públicas ou privadas, são normalmente o motor de todos os processos e operações realizadas nas aplicações.',
            theProcess: 'O Processo',
            theProcessText: 'O que pode esperar desde o momento em que nos contacta até à conclusão da aplicação',
            projectAssessment: 'Avaliação de Projectos',
            projectAssessmentText: 'Depois de compreendermos o conceito e as funcionalidades que deseja para a aplicação, damos-lhe uma ideia geral dos valores e dos prazos do projecto. Oferecemos a nossa experiência para que juntos possamos melhorar o projecto.',
            designUiUx: 'Design UI/UX',
            designUiUxText: 'Após a reunião de arranque de projeto, iremos desenhar o flow da aplicação, bem como aplicar design. Enviar-lhe-emos um protótipo navegável baseado no design que criámos.',
            development: 'Desenvolvimento',
            developmentText1: 'Uma vez aprovados o design e flow que criámos, passaremos à fase de desenvolvimento',
            developmentText2: 'Na gestão do projecto dividiremos o projecto em Sprints (fases) para que possa testar a aplicação ao longo do projeto. Isto permitir-lhe-á fazer correcções e melhorias.',
            testing: 'Testes',
            testingText: 'No final do desenvolvimento, entraremos na fase final de testes, com a nossa equipa de QA a testar a aplicação para a entregar com um mínimo de erros, e há também uma bateria de testes do seu lado para corrigir o maior número possível de bugs.',
            deploy: 'Deploy',
            deployText1: 'Depois de corrigir os erros detectados, iremos colocar a aplicação nas stores, se for uma aplicação móvel, ou colocá-la online, se for uma aplicação web.',
            deployText2: 'Após a conclusão da aplicação, pode contar connosco para apoio e manutenção, o que inclui desenvolvimento de novas funcionalidades, bem como a adaptação da aplicação aos requisitos legais, que possam existir.',
            deployText3: 'Para correcção de erros, tome nota que damos 24 meses de garantia.',
        },

        about: {
            subTitle: 'Somos profissionais mas muito descontraídos',
            aboutUsText1: 'A Dev2Grow é uma empresa jovem, dinâmica que nasce a partir de recursos com mais de 15 anos de experiência no setor das Tecnologias de Informação, acompanhando e apoiando o crescimento de recursos jovens com capacidades extraordinárias, todos saídos do Instituto Politécnico de Setúbal, com quem temos uma parceria de colaboração e de desenvolvimento.',
            aboutUsText2: 'Especializados em desenvolvimento web e de aplicações móveis, tentamos levar ao mundo, colocando no bolso de cada pessoa através do seu dispositivo móvel, o nosso trabalho, o nosso empenho, o nosso profissionalismo e capacidade.',
            whyUs: 'Porquê Nós',
            whyUsText1: 'A S4 Digital, via aquisição da Dev2Grow, é uma das principais empresas de desenvolvimento de aplicações móveis e web.',
            whyUsText2: 'Dê-nos uma ideia e trabalhamos em conjunto na melhor solução para a mesma.',
            someCount: 'Alguns números que contam',
            someCountText: 'As nossas conquistas em números',
            projectConsultations: 'Análise de Projetos',
            yearsExperience: 'Anos de Experiência',
            developedProjects: 'Projetos Desenvolvidos',
            team: 'A nossa equipa',
            projectSimulation: 'Estimativa de Projeto',
            sentSuccessfully: 'A simulação foi enviada com sucesso.',
        },

        work: {
            subTitle: 'Podes esperar uma vista do rio Sado e da Serra da Arrábida nos nossos escritórios ou trabalhar no teu sofá',
            workWithUs: 'Trabalha Connosco',
            workWithUsText: 'Proporcionamos um ambiente de trabalho descontraído e uma oportunidade de crescimento profissional e pessoal. Envia a tua candidatura e vem descobrir tudo o que o Dev2Grow pode oferecer-te. Muito mais que uma carreira, construímos o teu futuro e a tua felicidade.',
            friendly: 'Ambiente de Trabalho Amigável',
            drinks: 'Bebidas',
            tools: 'Ferramentas de Trabalho',
            fun: 'Fun Room',
            jobsOpportunities: 'Oportunidades',
            days: 'dias',
            day: 'dia',
            ago: 'atrás',
            hours: 'horas',
            hour: 'hora',
            applyNow: 'Quero Candidatar-me',
            apply: 'Candidatar',
            recruitmentProcess: 'Processo de Recrutamento',
            sendCV: 'Enviar CV',
            sendCVText: 'Candidata-te a uma oferta de emprego ou envia-nos o teu CV',
            contact: 'Contato',
            contactText: 'Entraremos em contacto contigo por e-mail ou telefone para agendar uma reunião',
            interview: 'Entrevista',
            interviewText: 'A entrevista é uma conversa amigável para nos conhecermos melhor',
            task: 'Tarefa',
            taskText: 'Não é nada complicado, apenas para compreender melhor as tuas capacidades',
            onboarding: 'Onboarding',
            onboardingText: 'Bem-vindo à nossa equipa',
            joinTeam: 'Junta-te à nossa equipa!',
            joinTeamText: 'Se não houver vagas que te interessem, envia-nos o teu CV. Pessoas talentosas e felizes são sempre bem-vindas!',
            uploadCV: 'Envia o teu CV',
            location: 'Localização',
            offerDescription: 'Descrição da Oferta',
            requirements: 'Requisitos',
            pleaseFill: 'Preenche por favor o formulário abaixo para te candidatares a esta oferta',
            consent: 'Autorizo que os meus dados sejam recolhidos e utilizados para fins de recrutamento interno',
            requestSent: 'A tua candidatura foi registada com sucesso.',
            acceptConsent: 'Tem de concordar em consentir que as suas informações sejam processadas.',
            seeOtherOffers: 'Ver Outras Ofertas',
            unsupportedFileType: 'Tipo de ficheiro não suportado',
            unsupportedFileTypeText: 'O ficheiro que seleccionou tem uma extensão não apoiada. Por favor, carregue uma imagem ou PDF.',
            uploadCVMandatory: 'Deve carregar um CV antes de submeter a sua candidatura.',
            selectJob: 'Candidatura a *',
            mustSelectJob: 'Deverá seleccionar um trabalho ao qual se candidatar.',
        },
    }
});
