/*
 *
 * Home
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, notification, Row } from 'antd';
import { Icon, SVGs, TestimonialCard } from 'components';
import Carousel from 'react-multi-carousel';
// @ts-ignore
import { Popup } from 'react-typeform-embed';
import Strings from 'utils/strings';
import './styles.scss';

import { translate } from 'utils/utils';
import { API, Endpoints } from 'utils/api';
import { setLoader } from 'store/actions';

export class Home extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            testimonials: [],
        }
    }

    componentDidMount() {
        this.getTestimonials();
    }

    async getTestimonials() {
        const { dispatch } = this.props;

        dispatch(setLoader(true));

        try {
            const response = await API.get({
                url: Endpoints.uriPortfolio('reviews'),
            });

            if (response.ok) {
                this.setState({ testimonials: response.data.results || [] });
            }
        } catch (err) {
            console.log('Error', err);
        }

        dispatch(setLoader(false));
    }

    renderClients() {
        return (
            <div className="clients-outer-container">
                <section className="clients-wrapper">
                    <h2>{Strings.home.trustedBy}</h2>
                    <div className="clients-container">
                        <SVGs.Volup />
                        <SVGs.GoodBoost height={80} width={200} />
                        <SVGs.Osae />
                        <SVGs.Veenion />
                        {/* <SVGs.Sparkl /> */}
                        <SVGs.Era height={80} />
                        <SVGs.Fertiberia />
                    </div>
                </section>
            </div>
        );
    }

    renderJobs() {
        return (
            <div className="work-with-us-outer-container">
                <section className="work-with-us-wrapper">
                    <h2>{Strings.home.workWithUs}</h2>
                    <Row style={{ width: '100%' }} gutter={[80, 40]}>
                        <Col xs={24} lg={12}>
                            <div className="reason-container">
                                <div className="reason-title">
                                    <SVGs.Lamp height={125} width={125} />
                                    <h4>{Strings.home.createVision}</h4>
                                </div>
                                <p>{Strings.home.createVisionText}</p>
                                <p>{Strings.home.createVisionText2}</p>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="reason-container">
                                <div className="reason-title">
                                    <SVGs.Clock height={125} width={125} />
                                    <h4>{Strings.home.punctual}</h4>
                                </div>
                                <p>{Strings.home.punctualText}</p>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="reason-container">
                                <div className="reason-title">
                                    <SVGs.Staff height={125} width={125} />
                                    <h4>{Strings.home.supportCompanies}</h4>
                                </div>
                                <p>{Strings.home.supportCompaniesText}</p>
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div className="reason-container">
                                <div className="reason-title">
                                    <SVGs.Book height={125} width={125} />
                                    <h4>{Strings.home.projectManagement}</h4>
                                </div>
                                <p>{Strings.home.projectManagementText}</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>
        );
    }

    renderWhatWeDo() {
        return (
            <div className="what-we-do-outer-container">
                <section className="what-we-do">
                    <div className="what-we-do-content">
                        <h2>{Strings.home.whatWeDo}</h2>
                        <div className="services-wrapper">
                            <div className="service-card-wrapper">
                                <SVGs.Design />
                                <h5>{Strings.home.uiuxDesign}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.AppDevelopment />
                                <h5>{Strings.home.mobileDevelopment}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.WebDevelopment />
                                <h5>{Strings.home.webDevelopment}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.SoftwareIntegration />
                                <h5>{Strings.home.backendDevelopment}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.DataManagement />
                                <h5>{Strings.home.dataExpertise}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.API />
                                <h5>{Strings.home.softwareIntegration}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.DevOps />
                                <h5>{Strings.home.devOps}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.ProjectManagement />
                                <h5>{Strings.home.projectManagementSingle}</h5>
                            </div>
                            <div className="service-card-wrapper">
                                <SVGs.Outsourcing />
                                <h5>{Strings.home.outsourcing}</h5>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    renderTestimonials() {
        const { testimonials } = this.state;
        const carouselResponsive = {
            desktop: {
                breakpoint: { max: 8000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 0 },
                items: 1,
            },
        };

        return (
            <div className="clients-testimonials-outer-container">
                <section className="client-testimonials">
                    <h2>{Strings.home.clientsSay}</h2>
                    <div className="client-testimonials-carousel">
                        <Carousel
                            responsive={carouselResponsive}
                            swipeable={true}
                            draggable={true}
                            infinite={true}
                            arrows={false}
                            autoPlay={false}
                            itemClass="carousel-item-padding"
                            showDots={true}
                        >
                            {testimonials.map((testimonial: any, index: number) => (
                                <TestimonialCard
                                    key={`testimonial_${index}`}
                                    image={testimonial.reviewImage}
                                    testimonial={translate(testimonial.review)}
                                    client={testimonial.reviewName}
                                    hierarchy={translate(testimonial.reviewPosition)}
                                />
                            ))}
                        </Carousel>
                    </div>
                </section>
            </div>
        );
    }

    render() {
        const { surveys } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>S4 Digital | {Strings.sidebar.home}</title>
                    <meta name="description" content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile" />
                </Helmet>
                <div className="home-wrapper">
                    <div className="splash-outer-container">
                        <div className="splash-background">
                            <SVGs.Waves />
                        </div>
                        <section className="splash-wrapper">
                            <div className="main-title">
                                <Row gutter={[0, 40]}>
                                    <Col xs={24} md={20}>
                                        <h1>{Strings.home.titleS4} <span style={{ color: '#EB1E6E' }}>S4 Digital</span></h1>
                                        <h1>{Strings.home.title}</h1>
                                    </Col>
                                    <Col xs={24}>
                                        <Row>
                                            <Col xs={24} md={15} lg={7}>
                                                <p>{Strings.home.subTitle}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24}>
                                        <div className="estimate-project-container">
                                            <Popup
                                                id={translate(surveys)}
                                                size={80}
                                                autoClose={true}
                                                onSubmit={() =>
                                                    notification.success({
                                                        message: Strings.about.projectSimulation,
                                                        description: Strings.about.sentSuccessfully,
                                                        duration: 5,
                                                        placement: 'bottomRight',
                                                    })
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="btn-primary --btn-icon"
                                                    style={{ minHeight: 60, fontSize: 18 }}
                                                    onClick={() => { }}
                                                >
                                                    {Strings.home.estimateProject}
                                                    <Icon name="back" className="--icon-rotate-180" />
                                                </button>
                                            </Popup>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>
                    {this.renderClients()}
                    {this.renderJobs()}
                    {this.renderWhatWeDo()}
                    {this.renderTestimonials()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
    surveys: state.surveys,
});

export default connect(mapStateToProps)(Home);
