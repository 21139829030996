// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Content{display:flex;flex-direction:column;width:100%;height:100%;position:relative;box-shadow:0px 2px 20px #0000000a,0px 8px 32px #00000014}@media screen and (max-width: 992px){.Content{min-width:100%}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
