import * as TYPES from '../constants';
import { store } from 'store';

export const setLoader = (value: boolean) => ({ type: TYPES.SET_LOADER, value });
export const setTitle = (value: string) => ({ type: TYPES.SET_TITLE, value });
export const setUser = (value: object) => ({ type: TYPES.SET_USER, value });
export const setToken = (value: string) => ({ type: TYPES.SET_TOKEN, value });
export const setLogout = () => ({ type: TYPES.SET_LOGOUT });
export const setUpdater = (dispatch: any) => ({ type: TYPES.SET_UPDATER, dispatch });
export const setLanguage = (value: string) => ({ type: TYPES.SET_LANGUAGE, value });
export const setSurveys = (value: any) => ({ type: TYPES.SET_SURVEYS, value });
export const setNavigation = (value: string) => ({ type: TYPES.SET_NAVIGATION, value});

export const delayedDispatch = (value: any, time = 400) => {
	setTimeout(() => store.dispatch(value), time);
}