import React from 'react';
import { connect } from 'react-redux';

import './styles.scss';

class TestimonalCard extends React.PureComponent<any, any> {
    render() {
        return (
            <div className="testimonal-wrapper">
                <div className="testimonial-client-photo" style={{ backgroundImage: `url('${this.props.image}')` }} />
                <div className="testimonial-content">
                    <p>{this.props.testimonial}</p>
                    <div className="testimonial-who">
                        <p>{this.props.client}</p>
                        <p>{this.props.hierarchy}</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(TestimonalCard);