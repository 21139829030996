/*
*
* Footer
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Col, Row } from 'antd';
import { Icon, SVGs } from 'components';
import Strings from 'utils/strings';
import './styles.scss';
import { DateTime } from 'luxon';

export class Footer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            isMobile: window.innerWidth <= 992,
        };

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 992 });
    }

    render() {
        const { dispatch } = this.props;
        const { isMobile } = this.state;

        return (
            <React.Fragment>
                <div className="footer-wrapper">
                    <div className="footer-outer-container" />
                    <div className="footer-background">
                        {isMobile ? <SVGs.Waves3 /> : <SVGs.Waves2 />}
                    </div>
                    <div className="footer-inner-container">
                        <Row gutter={[20, 0]}>
                            <Col xs={24} lg={{ span: 18, offset: 6 }} xl={{ span: 14, offset: 10 }}>
                                <Row gutter={[20, 20]}>
                                    <Col xs={12} md={8}>
                                        <div className="footer-office">
                                            <div className="footer-address">
                                                <h4>Headquarters</h4>
                                                <p>Av. José Francisco Guerreiro</p>
                                                <p>Paiã Park - Edifício A2</p>
                                                <p>1675-076 Lisboa</p>
                                                <p>Portugal</p>
                                            </div>
                                            {/* <div className="footer-phone">
                                                <Icon name="call" />
                                                <a href="tel:+351800180283">+351 800 180 283</a>
                                            </div> */}
                                            <div className="footer-phone">
                                                <Icon name="email" />
                                                <a href="mailto:info@dev2grow.com">info@dev2grow.com</a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <div className="footer-office">
                                            <div className="footer-address">
                                                <h4>Setúbal</h4>
                                                <p>Avenida Bento Gonçalves, 32J</p>
                                                <p>2910-431 Setúbal</p>
                                                <p>Portugal</p>
                                            </div>
                                            {/* <div className="footer-phone">
                                                <Icon name="email" />
                                                <a href="mailto:info@dev2grow.com">info@dev2grow.com</a>
                                            </div> */}
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <nav className="footer-navigation">
                                            <a
                                                href="/"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault();

                                                    dispatch(push('/'))
                                                }}
                                            >
                                                {Strings.header.home}
                                            </a>
                                            <a
                                                href="/about-us"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault();

                                                    dispatch(push('/about-us'))
                                                }}
                                            >
                                                {Strings.header.aboutUs}
                                            </a>
                                            <a
                                                href="/what-we-do"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault();

                                                    dispatch(push('/what-we-do'))
                                                }}
                                            >
                                                {Strings.header.whatWeDo}
                                            </a>
                                            <a
                                                href="/portfolio"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault();

                                                    dispatch(push('/portfolio'))
                                                }}
                                            >
                                                {Strings.header.portfolio}
                                            </a>
                                            <a
                                                href="/work-with-us"
                                                onClick={(e: React.MouseEvent) => {
                                                    e.preventDefault();

                                                    dispatch(push('/work-with-us'))
                                                }}
                                            >
                                                {Strings.header.workWithUs}
                                            </a>
                                        </nav>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    {isMobile ? (
                        <div className="footer-copyright-mobile">
                            <div className="footer-social">
                                <Icon onClick={() => window.open('https://www.facebook.com/dev2grow')} name="facebook" />
                                <Icon onClick={() => window.open('https://www.linkedin.com/company/dev2grow')} name="linkedin" />
                            </div>
                            <div className="footer-copyright-content">
                                <a
                                    href="/pages/privacy"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();

                                        dispatch(push('/pages/privacy'))
                                    }}
                                >
                                    {Strings.header.privacy}
                                </a>
                                <a
                                    href="/pages/clutch"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();

                                        dispatch(push('/pages/clutch'))
                                    }}
                                >
                                    {Strings.header.usOnClutch}
                                </a>
                            </div>
                            <p>&copy; 2023 S4 Digital - Powered by us with a lot of love :)</p>
                        </div>
                    ) : (
                        <div className="footer-copyright">
                            <div className="footer-copyright-content">
                                <p>&copy; {DateTime.utc().year} S4 Digital - Powered by us with a lot of love :)</p>
                                <a
                                    href="/pages/privacy"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();

                                        dispatch(push('/pages/privacy'))
                                    }}
                                >
                                    {Strings.header.privacy}
                                </a>
                                <a
                                    href="/pages/clutch"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();

                                        dispatch(push('/pages/clutch'))
                                    }}
                                >
                                    {Strings.header.usOnClutch}
                                </a>
                            </div>
                            <div className="footer-social">
                                <Icon onClick={() => window.open('https://www.linkedin.com/company/s4digital')} name="linkedin" />
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(Footer);