import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { setLoader } from "store/actions";
import { Checkbox, Col, Input, Modal, notification, Row } from "antd";
import { Icon, SVGs } from "components";
import { API, Endpoints } from "utils/api";
import Strings from "utils/strings";
import "./styles.scss";
import { translate } from "utils/utils";
import { DateTime } from "luxon";
import { push, replace } from "connected-react-router";
import { ClapSpinner } from "react-spinners-kit";

const ACCEPTED_MIME_TYPE = [
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/png",
    "application/pdf",
    "image/x-eps",
];

class WorkWithUs extends React.Component<any, any> {
    uploadInput: any;

    constructor(props: any) {
        super(props);

        this.state = {
            jobs: [],
            openModal: false,
            form: null,
            submittingForm: false,
        };

        this.uploadInput = React.createRef();
    }

    componentDidMount() {
        const { router, dispatch } = this.props;

        if (router?.location?.state?.moveTo === "jobs") {
            dispatch(replace("/work-with-us"));

            setTimeout(() => {
                const elem = document.querySelector("#job-offers");

                if (elem) {
                    window.scrollTo({
                        top: elem.getBoundingClientRect().top + window.scrollY,
                        behavior: "smooth",
                    });
                }
            }, 0);
        }

        this.getJobs();
    }

    async getJobs() {
        const { dispatch } = this.props;

        dispatch(setLoader(true));

        try {
            const response = await API.get({
                url: Endpoints.uriJobs(),
            });

            if (response.ok) {
                this.setState({ jobs: response.data.results.jobOportunities || [] });
            }
        } catch (err) {
            console.log("Error", err);
        }

        dispatch(setLoader(false));
    }

    async submitForm() {
        const { form } = this.state;

        if (!form?.name?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillName,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.email?.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillEmail,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.phone.trim()) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.home.fillPhone,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.offer) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.work.mustSelectJob,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.consent) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.work.acceptConsent,
                placement: "bottomRight",
                duration: 5,
            });
        } else if (!form?.cv) {
            return notification.warn({
                message: Strings.errors.invalidFields,
                description: Strings.work.uploadCVMandatory,
                placement: "bottomRight",
                duration: 5,
            });
        }

        this.setState({ submittingForm: true });

        const body = new FormData();

        body.append("name", form.name);
        body.append("email", form.email);
        body.append("phone", form.phone);
        body.append("region", form.region || '');
        body.append("offer", form.offer);
        body.append("availability", form.availability || '');
        body.append("wage", form.wage || '');
        body.append("consent", form.consent);
        body.append("linkedIn", form.linkedIn || '');

        body.append("cv", form.cv);

        const response = await API.post({
            url: Endpoints.uriApplyJob(),
            data: body,
        });

        if (response.ok) {
            this.setState({ submittingForm: false, form: null, openModal: false }, () => {
                notification.success({
                    message: Strings.work.jobsOpportunities,
                    description: Strings.work.requestSent,
                    placement: "bottomRight",
                    duration: 5,
                });
            });
        } else {
            notification.error({
                message: Strings.serverErrors.title,
                description: Strings.home.requestNotPossible,
                placement: "bottomRight",
                duration: 5,
            });
        }
    }

    renderJobs() {
        const { jobs } = this.state;
        const { dispatch } = this.props;

        return (
            <div className="job-opportunities-outer-container">
                <section id="job-offers" className="job-opportunities-wrapper">
                    <h2>{Strings.work.jobsOpportunities}</h2>
                    <Row gutter={[40, 20]}>
                        {jobs.map((job: any, index: number) => {
                            const diff = DateTime.utc().diff(
                                DateTime.fromISO(job.offerDate),
                                "days"
                            );
                            let date = `${Math.floor(diff.days)} ${Strings.work.days} ${Strings.work.ago
                                }` as any;

                            if (diff.days < 1) {
                                const hours = Math.ceil(diff.days * 24);
                                date = `${hours} ${hours === 1
                                    ? Strings.work.hour
                                    : Strings.work.hours
                                    } ${Strings.work.ago}`;
                            }

                            return (
                                <Col key={`job_offer_${job._id}`} xs={24} md={12} lg={8}>
                                    <div className="job-opportunity">
                                        <div className="job-opportunity-image" style={{ backgroundImage: `url('${job.image}')` }} />
                                        <div className="job-opportunity-content">
                                            <div className="job-date-location">
                                                <p>{translate(job.location)}</p>
                                                <p>{date}</p>
                                            </div>
                                            <h4>{job.title}</h4>
                                            <p>{translate(job.smallDescription)}</p>
                                            <a
                                                href={`/job-offer/${job.link}`}
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    dispatch(push(`/job-offer/${job.link}`));
                                                }}
                                                className="job-apply"
                                            >
                                                <p>{Strings.work.apply}</p>
                                                <Icon name="arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </section>
            </div>
        );
    }

    renderRecruitmentProcess() {
        return (
            <div className="recruitment-outer-container">
                <section className="recruitment-wrapper">
                    <h2>{Strings.work.recruitmentProcess}</h2>
                    <div className="recruitment-step-wrapper">
                        <div className="recruitment-step">
                            <div className="recruitment-step-illustration">
                                <div className="recruitment-icon-wrapper">
                                    <SVGs.CV width={24} height={24} />
                                </div>
                                <div className="recruiment-step-vector">
                                    <svg
                                        width="165"
                                        height="28"
                                        viewBox="0 0 165 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 2.84741C1 2.84741 49.7635 -1.80109 85.025 4.41646C120.286 10.634 163.493 26.4994 163.493 26.4994"
                                            stroke="#425776"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeDasharray="2 6"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4>{Strings.work.sendCV}</h4>
                            <p>{Strings.work.sendCVText}</p>
                        </div>
                        <div className="recruitment-step">
                            <div className="recruitment-step-illustration">
                                <div className="recruitment-icon-wrapper">
                                    <SVGs.Contact width={24} height={24} />
                                </div>
                                <div className="recruiment-step-vector">
                                    <svg
                                        width="165"
                                        height="28"
                                        viewBox="0 0 165 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 2.84741C1 2.84741 49.7635 -1.80109 85.025 4.41646C120.286 10.634 163.493 26.4994 163.493 26.4994"
                                            stroke="#425776"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeDasharray="2 6"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4>{Strings.work.contact}</h4>
                            <p>{Strings.work.contactText}</p>
                        </div>
                        <div className="recruitment-step">
                            <div className="recruitment-step-illustration">
                                <div className="recruitment-icon-wrapper">
                                    <SVGs.Interview width={24} height={24} />
                                </div>
                                <div className="recruiment-step-vector">
                                    <svg
                                        width="166"
                                        height="31"
                                        className="--adjust-bottom"
                                        viewBox="0 0 166 31"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.80664 25.2432C1.80664 25.2432 50.5702 32.8917 85.8316 26.6741C121.093 20.4566 164.3 1.59121 164.3 1.59121"
                                            stroke="#425776"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeDasharray="2 6"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4>{Strings.work.interview}</h4>
                            <p>{Strings.work.interviewText}</p>
                        </div>
                        <div className="recruitment-step">
                            <div className="recruitment-step-illustration">
                                <div className="recruitment-icon-wrapper">
                                    <SVGs.Task width={24} height={24} />
                                </div>
                                <div className="recruiment-step-vector">
                                    <svg
                                        width="165"
                                        height="28"
                                        viewBox="0 0 165 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1 2.84741C1 2.84741 49.7635 -1.80109 85.025 4.41646C120.286 10.634 163.493 26.4994 163.493 26.4994"
                                            stroke="#425776"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeDasharray="2 6"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h4>{Strings.work.task}</h4>
                            <p>{Strings.work.taskText}</p>
                        </div>
                        <div className="recruitment-step">
                            <div className="recruitment-step-illustration">
                                <div className="recruitment-icon-wrapper">
                                    <SVGs.Onboarding width={24} height={24} />
                                </div>
                            </div>
                            <h4>{Strings.work.onboarding}</h4>
                            <p>{Strings.work.onboardingText}</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    renderJoinTeam() {
        return (
            <div className="join-team-outer-container">
                <section className="join-team-wrapper">
                    <Row gutter={20}>
                        <Col xs={0} md={12}>
                            <div className="join-team-background" />
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="join-team-container">
                                <h3>{Strings.work.joinTeam}</h3>
                                <p>{Strings.work.joinTeamText}</p>
                                <button
                                    onClick={() => {
                                        this.setState({ openModal: true });
                                    }}
                                    className="btn-primary --btn-icon"
                                >
                                    {Strings.work.uploadCV}
                                    <Icon name="upload" />
                                </button>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>
        );
    }

    renderModal() {
        const { openModal, form, submittingForm } = this.state;

        return (
            <Modal
                title={null}
                footer={null}
                width={1000}
                visible={openModal}
                centered
                className="apply-modal"
                maskStyle={{ backgroundColor: "#00000080" }}
                bodyStyle={{ padding: 0 }}
                onCancel={() =>
                    this.setState({ openModal: false, selectedIndex: null })
                }
            >
                <div className="modal-apply-container">
                    <h3>{Strings.work.apply}</h3>
                    <h4>{Strings.work.pleaseFill}</h4>
                    <form
                        className="modal-apply-form"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (!submittingForm) {
                                this.submitForm();
                            }
                        }}
                    >
                        <Row gutter={[20, 20]}>
                            <Col xs={24} md={18}>
                                <Input
                                    value={form?.name}
                                    placeholder={Strings.fields.name}
                                    required
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, name: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <Input
                                    value={form?.phone}
                                    placeholder={Strings.fields.phone}
                                    required
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, phone: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={18}>
                                <Input
                                    value={form?.email}
                                    placeholder={Strings.fields.email}
                                    type="email"
                                    required
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, email: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={6}>
                                <Input
                                    value={form?.region}
                                    placeholder={Strings.fields.region}
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, region: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <Input
                                    value={form?.offer}
                                    placeholder={Strings.work.selectJob}
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, offer: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <Input
                                    value={form?.availability}
                                    placeholder={Strings.fields.availability}
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, availability: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <Input
                                    value={form?.wage}
                                    placeholder={Strings.fields.desiredWage}
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, wage: value },
                                        }));
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <Input
                                    value={form?.linkedIn}
                                    placeholder={Strings.fields.linkedIn}
                                    onChange={(e: any) => {
                                        const value = e.target.value;

                                        this.setState((prevState: any) => ({
                                            form: { ...prevState.form, linkedIn: value },
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                        <div className="modal-upload-cv">
                            <label className="btn-upload --btn-icon">
                                <input
                                    id="upload_cv_input"
                                    type="file"
                                    ref={this.uploadInput}
                                    accept={ACCEPTED_MIME_TYPE.join(",")}
                                    onChange={(e: any) => {
                                        const file = e.target.files[0];

                                        if (ACCEPTED_MIME_TYPE.includes(file.type)) {
                                            this.setState((prevState: any) => ({
                                                form: { ...prevState.form, cv: file },
                                            }));
                                        } else {
                                            notification.warn({
                                                message: Strings.work.unsupportedFileType,
                                                description: Strings.work.unsupportedFileTypeText,
                                                placement: "bottomRight",
                                                duration: 5,
                                            });
                                        }
                                    }}
                                />
                                <Icon name="upload" />
                                Upload CV
                            </label>
                            {Boolean(form?.cv) && (
                                <div className="modal-uploaded-file">
                                    <Icon name="clip" />
                                    <p>{form?.cv?.name}</p>
                                    <Icon
                                        name="bin --icon-clickable"
                                        onClick={() => {
                                            if (this.uploadInput) {
                                                this.uploadInput.current.value = "";
                                            }

                                            this.setState((prevState: any) => ({
                                                form: { ...prevState.form, cv: null },
                                            }));
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <Checkbox
                            checked={form?.consent}
                            onChange={(e: any) => {
                                const value = e.target.checked;
                                this.setState((prevState: any) => ({
                                    form: { ...prevState.form, consent: value },
                                }));
                            }}
                        >
                            {Strings.work.consent}
                        </Checkbox>
                        <div className="modal-button-wrapper">
                            <button
                                type="submit"
                                className="btn-primary --btn-icon --btn-m10t"
                            >
                                {Strings.work.applyNow}
                                {submittingForm ? (
                                    <ClapSpinner frontColor="#FFF" backColor="#FFF" size={18} />
                                ) : (
                                    <Icon name="back" className="--icon-rotate-180" />
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>S4 Digital | {Strings.header.whatWeDo}</title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="work-with-us-wrapper">
                    <div className="splash-outer-container">
                        <div className="splash-background">
                            <SVGs.Waves />
                        </div>
                        <section className="splash-wrapper">
                            <div className="main-title">
                                <h1>{Strings.header.workWithUs}</h1>
                                <h3>{Strings.work.subTitle}</h3>
                            </div>
                        </section>
                    </div>
                    <div className="work-with-us-outer-container">
                        <section className="work-with-us">
                            <h2>{Strings.work.workWithUs}</h2>
                            <p>{Strings.work.workWithUsText}</p>
                            <div className="work-perks">
                                <Row gutter={[20, 20]}>
                                    <Col xs={24} sm={12} lg={6}>
                                        <div className="work-perk">
                                            <div className="work-perk-image image-1" />
                                            <span>
                                                <p>{Strings.work.friendly}</p>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} lg={6}>
                                        <div className="work-perk">
                                            <div className="work-perk-image image-2" />
                                            <span>
                                                <p>{Strings.work.drinks}</p>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} lg={6}>
                                        <div className="work-perk">
                                            <div className="work-perk-image image-3" />
                                            <span>
                                                <p>{Strings.work.tools}</p>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} lg={6}>
                                        <div className="work-perk">
                                            <div className="work-perk-image image-4" />
                                            <span>
                                                <p>{Strings.work.fun}</p>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>
                    {this.renderJobs()}
                    {this.renderRecruitmentProcess()}
                    {this.renderJoinTeam()}
                    {this.renderModal()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
    router: state.router,
});

export default connect(mapStateToProps)(WorkWithUs);
