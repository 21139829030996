import React from "react";
import { connect } from "react-redux";
import strings from "utils/strings";
import "./styles.scss";

class PortfolioCard extends React.PureComponent<any, any> {
    render() {
        const { onClick } = this.props;

        return (
            <div
                className="portfolio-card-wrapper"
                onClick={() => {
                    if (
                        typeof onClick === "function" &&
                        !this.props.workInProgress &&
                        !this.props.toBeReleased
                    ) {
                        onClick();
                    }
                }}
            >
                <div
                    style={{ backgroundImage: `url('${this.props.image}')` }}
                    className={`project-thumbnail${(this.props.workInProgress || this.props.toBeReleased) ? ' --project-disabled' : ''}`}
                >
                    <div
                        style={{ backgroundImage: `url('${this.props.thumbnail}')` }}
                        className="project-favicon"
                    />
                </div>
                <div className="project-description">
                    <h4>
                        {!this.props.workInProgress && !this.props.toBeReleased && this.props.name}
                        {this.props.workInProgress && !this.props.toBeReleased
                            ? ` ${strings.portfolio.workInProgress}`
                            : ""}
                        {this.props.toBeReleased
                            ? ` ${strings.portfolio.toBeReleased}`
                            : ""}
                    </h4>
                    <p>{this.props.description}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(PortfolioCard);
