import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, ErrorBoundary, Footer } from "screens";
import { setLanguage } from "store/actions";
import { ContactUs, Content, Loader } from "components";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Routes from "./routes";
import strings from "utils/strings";

import 'styles/styles.scss';
import 'styles/theme.less';
import "react-multi-carousel/lib/styles.css";
import "html5-device-mockups/dist/device-mockups.min.css";

let theme: any;

export class App extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            sidebarOpen: false,
            sidebarHidden: false,
        };

        theme = createTheme({
            palette: {
                primary: {
                    main: '#17445f',
                },
            },
            // typography: { 
            // 	useNextVariants: true,
            // },
        });

        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize);
    }

    UNSAFE_componentWillMount() {
        const { dispatch, language } = this.props;

        let newLanguage = language;
        if (!newLanguage) {
            switch(navigator.language) {
                case 'pt':
                case 'pt-BR':
                case 'pt-PT':
                    newLanguage = 'pt';
                    break;
                default:
                    newLanguage = 'en';
            }
        }

        strings.setLanguage(newLanguage || 'en');
        dispatch(setLanguage(newLanguage || 'en'));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.router?.location?.pathname !== prevProps.router?.location?.pathname) {
            window.scroll({ top: 0 });
        }
    }

    handleResize() {
        const isMobile = window.innerWidth < 992;
        this.setState({ isMobile });
    }

    openSidebar() {
        this.setState({ sidebarOpen: true });
    }

    closeSidebar() {
        if (document.body.clientWidth < 992) {
            this.setState({ sidebarHidden: true, sidebarOpen: false });
        } else {
            this.setState({ sidebarOpen: false });
        }
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Content>
                    <ErrorBoundary>
                        <Header
                            sidebarOpen={this.state.sidebarOpen}
                            onToggleSidebar={() => {
                                this.setState((state: any) => ({
                                    sidebarHidden: !state.sidebarHidden,
                                    sidebarOpen: !state.sidebarOpen,
                                }));
                            }}
                        />
                        <Routes />
                        {window.location?.pathname !== '/500' && (<ContactUs />)}
                        <Footer />
                    </ErrorBoundary>
                </Content>
                <Loader />
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = (state: any) => ({
    router: state.router,
    loader: state.loader,
    language: state.language,
});

export default connect(mapStateToProps)(App);
