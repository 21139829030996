// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h1{color:#eb1e6e}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
