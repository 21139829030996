// TODO: Need better solution for this
const API_URL = process.env.API_URL;

export default {
	uriSurveys(id = '') {
		return `${API_URL}/surveys`;
	},

	uriPortfolio(id = '') {
		return `${API_URL}/projects/${id}`;
	},

	uriJobs(id = '') {
		return `${API_URL}/job-oportunities/${id}`;
	},

	uriApplyJob() {
		return `${API_URL}/misc/job-apply`;
	},

	uriContactUs() {
		return `${API_URL}/misc/contact`;
	},

	uriPages(id = '') {
		return `${API_URL}/pages/${id}`;
	},
};
