import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Icon, SVGs } from "components";
import Strings from "utils/strings";
import { Col, Row } from "antd";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./styles.scss";

class WhatWeDo extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    componentDidMount() { }

    renderTechnologies() {
        return (
            <div className="technologies-outer-container">
                <section className="technologies-wrapper">
                    <h3>{Strings.whatWeDo.ourTechnologies}</h3>
                    <div className="technologies-list">
                        <div className="technology-container">
                            <h4 className="technology-title">
                                {Strings.whatWeDo.mobileTechnologies}
                            </h4>
                            <Row gutter={20}>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="reactjs" />
                                        </div>
                                        <p>React Native</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="flutter" />
                                        </div>
                                        <p>Flutter</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="expo" />
                                        </div>
                                        <p>Expo</p>
                                    </div>
                                </Col>
                            </Row>
                            <p>{Strings.whatWeDo.mobileTechnologiesText}</p>
                        </div>
                        <div className="technology-separator" />
                        <div className="technology-container --border">
                            <h4 className="technology-title">
                                {Strings.whatWeDo.webTechnologies}
                            </h4>
                            <Row gutter={20}>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="reactjs" />
                                        </div>
                                        <p>React</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="expo" />
                                        </div>
                                        <p>Expo</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="vue" />
                                        </div>
                                        <p>Vue</p>
                                    </div>
                                </Col>
                            </Row>
                            <p>{Strings.whatWeDo.webTechnologiesText}</p>
                        </div>
                        <div className="technology-separator" />
                        <div className="technology-container --mobile">
                            <h4 className="technology-title">
                                {Strings.whatWeDo.backendTechnologies}
                            </h4>
                            <Row gutter={20}>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="nodejs" />
                                        </div>
                                        <p>Node JS</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="mongodb-outline" />
                                        </div>
                                        <p>MongoDB</p>
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <div className="technology-icon-group">
                                        <div className="technology-icon">
                                            <Icon name="postgre-sql" />
                                        </div>
                                        <p>PostgreSQL</p>
                                    </div>
                                </Col>
                            </Row>
                            <p>{Strings.whatWeDo.backednTechnologiesText}</p>
                        </div>
                    </div>
                    <div className="technologies-process">
                        <h3>{Strings.whatWeDo.theProcess}</h3>
                        <h4>{Strings.whatWeDo.theProcessText}</h4>
                    </div>
                </section>
            </div>
        );
    }

    renderProcess() {
        return (
            <div className="process-outer-container">
                <div className="process-wrapper">
                    <div className="process-background">
                        <SVGs.Rocket
                            width={400}
                            height={300}
                        />
                    </div>
                    <VerticalTimeline
                        animate={window.innerWidth < 1169 ? false : true}
                        // @ts-ignore
                        lineColor="#4256A6"
                    >
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "#4256A6", color: "#fff" }}
                            contentArrowStyle={{ borderRight: "7px solid #4256A6" }}
                            iconStyle={{ background: "#4256A6", color: "#fff" }}
                            position="right"
                            icon={
                                <div className="timeline-icon-container">
                                    <Icon name="process-assessment" />
                                </div>
                            }
                        >
                            <div className="timeline-card-container">
                                <h5>{Strings.whatWeDo.projectAssessment}</h5>
                                <p>{Strings.whatWeDo.projectAssessmentText}</p>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work timeline-left-container"
                            contentStyle={{ background: "#4256A6", color: "#fff" }}
                            contentArrowStyle={{ borderRight: "7px solid #4256A6" }}
                            iconStyle={{ background: "#4256A6", color: "#fff" }}
                            position="left"
                            icon={
                                <div className="timeline-icon-container">
                                    <Icon name="process-design" />
                                </div>
                            }
                        >
                            <div className="timeline-card-container --card-align-right">
                                <h5>{Strings.whatWeDo.designUiUx}</h5>
                                <p>{Strings.whatWeDo.designUiUxText}</p>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "#4256A6", color: "#fff" }}
                            contentArrowStyle={{ borderRight: "7px solid #4256A6" }}
                            iconStyle={{ background: "#4256A6", color: "#fff" }}
                            position="right"
                            icon={
                                <div className="timeline-icon-container">
                                    <Icon name="process-development" />
                                </div>
                            }
                        >
                            <div className="timeline-card-container">
                                <h5>{Strings.whatWeDo.development}</h5>
                                <p>{Strings.whatWeDo.developmentText1}</p>
                                <p>{Strings.whatWeDo.developmentText2}</p>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work timeline-left-container"
                            contentStyle={{ background: "#4256A6", color: "#fff" }}
                            contentArrowStyle={{ borderRight: "7px solid #4256A6" }}
                            iconStyle={{ background: "#4256A6", color: "#fff" }}
                            position="left"
                            icon={
                                <div className="timeline-icon-container">
                                    <Icon name="process-testing" />
                                </div>
                            }
                        >
                            <div className="timeline-card-container --card-align-right">
                                <h5>{Strings.whatWeDo.testing}</h5>
                                <p>{Strings.whatWeDo.testingText}</p>
                            </div>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: "#4256A6", color: "#fff" }}
                            contentArrowStyle={{ borderRight: "7px solid #4256A6" }}
                            iconStyle={{ background: "#4256A6", color: "#fff" }}
                            position="right"
                            icon={
                                <div className="timeline-icon-container">
                                    <Icon name="process-deploy" />
                                </div>
                            }
                        >
                            <div className="timeline-card-container">
                                <h5>{Strings.whatWeDo.deploy}</h5>
                                <p>{Strings.whatWeDo.deployText1}</p>
                                <p>{Strings.whatWeDo.deployText2}</p>
                                <p className="--p-bold">{Strings.whatWeDo.deployText3}</p>
                            </div>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>S4 Digital | {Strings.header.whatWeDo}</title>
                    <meta
                        name="description"
                        content="S4 Digital | Web and mobile apps developer company | Empresa de desenvolvimento web e mobile"
                    />
                </Helmet>
                <div className="what-we-do-wrapper">
                    <div className="splash-outer-container">
                        <div className="splash-background">
                            <SVGs.Waves />
                        </div>
                        <section className="splash-wrapper">
                            <div className="main-title">
                                <h1>{Strings.header.whatWeDo}</h1>
                                <h2>{Strings.whatWeDo.subTitle}</h2>
                            </div>
                        </section>
                    </div>
                    {this.renderTechnologies()}
                    {this.renderProcess()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    language: state.language,
});

export default connect(mapStateToProps)(WhatWeDo);
