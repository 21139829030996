// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".page-wrapper{display:flex;flex-direction:column}.page-wrapper .splash-outer-container{display:flex;justify-content:center;background-color:#0d1117}.page-wrapper .splash-wrapper{display:flex;justify-content:space-between;align-items:center;padding:120px 150px 0;width:100%;max-width:1920px}.page-wrapper .splash-wrapper .navigation-back{display:flex;align-items:center;width:80px;height:100px;background-color:transparent;border:none;cursor:pointer}.page-wrapper .splash-wrapper .navigation-back:hover em,.page-wrapper .splash-wrapper .navigation-back:focus em{color:#eb1e6e}.page-wrapper .splash-wrapper .navigation-back em{color:#fff;font-size:32px;transition:color .3s}.page-wrapper .splash-wrapper .main-title{display:flex;justify-content:center;position:relative;z-index:1;flex:1}.page-wrapper .splash-wrapper .main-title h1{color:#fff;text-transform:uppercase;margin:0}.page-wrapper .splash-wrapper .main-title p{font-size:18px;line-height:24px;color:#fff}.page-wrapper .splash-wrapper .dummy-container{width:80px}@media screen and (max-width: 480px){.page-wrapper .splash-wrapper{padding:100px 20px 0;min-height:160px}.page-wrapper .splash-wrapper .main-title h1{font-size:32px;line-height:40px}}@media screen and (min-width: 480px)and (max-width: 992px){.page-wrapper .splash-wrapper{padding:100px 20px 0;height:160px}.page-wrapper .splash-wrapper .navigation-back{height:40px}.page-wrapper .splash-wrapper .main-title h1{font-size:32px;line-height:40px}}.page-wrapper .page-outer-container{display:flex;justify-content:center;background-color:#fff}.page-wrapper .page-content{width:100%;max-width:1920px;padding:120px 150px}@media screen and (max-width: 992px){.page-wrapper .page-content{padding:60px 20px}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#eb1e6e",
	"secondaryColor": "#eb1e6e",
	"textColor": "#0d1117"
};
module.exports = exports;
