/* eslint-disable */

import React from 'react';
import Carousel from 'react-multi-carousel';
import { shouldBeWhite } from 'utils/utils';

class Device extends React.Component<any, any> {
    generateKey() {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    render() {
        const { section, project, index } = this.props;

        let invertColor = false;
        if (shouldBeWhite(project?.primaryColor)) {
            invertColor = true;
        }

        const carouselResponsive = {
            any: {
                breakpoint: { max: 4000, min: 0 },
                items: 1,
            },
        };

        const type = section.type.toLowerCase();
        let device = 'iPhoneX';

        switch (type) {
            case 'smartphone':
                device = 'iPhone7';
                break;
            case 'tablet':
                device = 'iPadPro';
                break;
            case 'web':
                device = 'iMac';
                break;
            case 'backend':
                device = 'MacbookPro';
                break;
            case 'design':
                device = 'MacbookPro';
                break;
            default:
                device = 'iMac';
                break;
        }

        const key = this.generateKey();

        return (
            <div className={`device-wrapper ${device}`}>
                <div
                    className="device"
                    data-device={device}
                    data-orientation={
                        type === "tablet" ? "landscape" : "portrait"
                    }
                    data-color={
                        ((type === "tablet" || type === "smartphone") && invertColor && index % 2 !== 0)
                            ? "white"
                            : "black"
                    }
                >
                    <div className="screen">
                        <Carousel
                            responsive={carouselResponsive}
                            swipeable={true}
                            draggable={true}
                            infinite={true}
                            arrows={false}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            showDots={false}
                            key={key}
                        >
                            {section.sectionImages?.map(
                                (image: string, index: number) => (
                                    <div
                                        key={`image_carousel_index_${index}`}
                                        className="section-image"
                                        style={{ backgroundImage: `url('${image}')` }}
                                    />
                                )
                            )}
                        </Carousel>
                    </div>
                    <div onClick={() => this.forceUpdate()} className="button" />
                </div>
            </div>
        )
    }
}

export default Device;